import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from '../../styles/icons/default.module.scss';

const propTypes = {
    className: PropTypes.string,
};

const defaultProps = {
    className: null,
};

const PlayIcon = ({ className }) => {
    return (
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            width="60px"
            height="60px"
            viewBox="0 0 60 60"
            className={classNames([styles.container, { [className]: className !== null }])}
            xmlSpace="preserve"
        >
            <g>
                <circle fill="#00A5AD" cx="30" cy="30" r="30" />
                <g>
                    <path
                        fill="#FFFFFF"
                        d="M23.6,17.4c-2.5-1.3-4.5-0.2-4.5,2.5v20.3c0,2.6,2,3.7,4.5,2.5l19.9-10.3c2.5-1.3,2.5-3.4,0-4.6L23.6,17.4z"
                    />
                </g>
            </g>
        </svg>
    );
};

PlayIcon.propTypes = propTypes;
PlayIcon.defaultProps = defaultProps;

export default PlayIcon;
