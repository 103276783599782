/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import { useGamePages } from '../contexts/PagesContext';
import { useUrlGenerator } from '../contexts/RoutesContext';

import styles from '../../styles/menus/indicators.module.scss';

const propTypes = {
    current: PropTypes.number,
    className: PropTypes.string,
};

const defaultProps = {
    current: 1,
    className: null,
};

const IndicatorMenu = ({ current, className }) => {
    const pages = useGamePages();
    const url = useUrlGenerator();
    return (
        <div className={classNames([styles.container, { [className]: className !== null }])}>
            {pages.map((p) => (
                <Link
                    className={classNames([styles.item, { [styles.active]: p.index <= current }])}
                    key={`indicator-${p.slug}`}
                    to={url('question', { slug: p.slug })}
                />
            ))}
        </div>
    );
};

IndicatorMenu.propTypes = propTypes;
IndicatorMenu.defaultProps = defaultProps;

export default IndicatorMenu;
