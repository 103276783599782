/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from '../../styles/animations/grid.module.scss';

const propTypes = {
    color: PropTypes.string,
    animated: PropTypes.bool,
    width: PropTypes.number,
    height: PropTypes.number,
    className: PropTypes.string,
};

const defaultProps = {
    color: 'currentColor',
    animated: false,
    width: 0,
    height: 0,
    className: null,
};

const Grid = ({ color, animated, width, height, className }) => {
    const divisionsCount = width > height ? 4 : 6;
    const crossDivisionsCount = width > height ? 12 : 4;
    const animationDuration = 2; // seconds

    const landscape = width > height;
    const strokeWidth = animated && landscape ? 4 : 2;
    const crossStrokeWidth = animated && landscape ? 8 : 2;
    const strokeMiterLimit = 10;

    const totalSvgWidth = width + strokeWidth;
    const totalSvgHeight = height + strokeWidth;

    const [showSvg, setShowSvg] = useState(false);
    useEffect(() => {
        setShowSvg(false);
        const timeout = setTimeout(setShowSvg, 0, true);
        return () => {
            clearTimeout(timeout);
        };
    }, [landscape]);

    return showSvg ? (
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            width={`${totalSvgWidth}px`}
            height={`${totalSvgHeight}px`}
            viewBox={`0 0 ${totalSvgWidth} ${totalSvgHeight}`}
            className={classNames([styles.container, { [className]: className !== null }])}
            xmlSpace="preserve"
            preserveAspectRatio="none"
        >
            <g id="crossLines">
                {[...new Array(divisionsCount)].map((division, divisionIndex) => {
                    const lineY = Math.round(
                        (height / (divisionsCount + 1)) * (divisionIndex + 1),
                    );

                    return (
                        <line
                            key={`line-${divisionIndex}`}
                            fill="none"
                            stroke={color}
                            strokeWidth={crossStrokeWidth}
                            strokeMiterlimit={strokeMiterLimit}
                            x1={crossStrokeWidth}
                            y1={lineY}
                            x2={width}
                            y2={lineY}
                        >
                            {animated ? (
                                <animateTransform
                                    attributeName="transform"
                                    type="translate"
                                    from={`0 ${height - lineY}`}
                                    to={`0 -${lineY}`}
                                    begin={`${
                                        (divisionIndex / divisionsCount) * animationDuration
                                    }s`}
                                    dur={`${animationDuration}s`}
                                    repeatCount="indefinite"
                                />
                            ) : null}
                        </line>
                    );
                })}
            </g>
            <g id="static">
                <polyline
                    fill="none"
                    stroke={color}
                    strokeWidth={crossStrokeWidth}
                    strokeMiterlimit={strokeMiterLimit}
                    points={`${strokeWidth},${crossStrokeWidth} ${width},${crossStrokeWidth} ${width},${height} ${strokeWidth},${height}, ${strokeWidth} ${crossStrokeWidth}`}
                />
                {[...new Array(crossDivisionsCount)].map((division, divisionIndex) => {
                    const lineX = Math.round(
                        (width / (crossDivisionsCount + 1)) * (divisionIndex + 1),
                    );

                    return (
                        <line
                            key={`line-${divisionIndex}`}
                            fill="none"
                            stroke={color}
                            strokeWidth={strokeWidth}
                            strokeMiterlimit={strokeMiterLimit}
                            x1={lineX}
                            y1={strokeWidth}
                            x2={lineX}
                            y2={height}
                        />
                    );
                })}
            </g>
        </svg>
    ) : null;
};

Grid.propTypes = propTypes;
Grid.defaultProps = defaultProps;

export default Grid;
