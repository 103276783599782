import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from '../../styles/icons/default.module.scss';

const propTypes = {
    color: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    color: 'currentColor',
    className: null,
};

const ExternalIcon = ({ color, className }) => {
    return (
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            width="14.98px"
            height="8px"
            viewBox="0 0 14.98 8"
            className={classNames([styles.container, { [className]: className !== null }])}
            xmlSpace="preserve"
        >
            <rect
                x="0.67"
                y="0.67"
                fill="none"
                stroke={color}
                strokeWidth="1.3333"
                strokeMiterlimit="10"
                width="6.67"
                height="6.67"
            />
            <polyline
                fill="none"
                stroke={color}
                strokeWidth="1.3333"
                strokeMiterlimit="10"
                points="10.7,0.7 14.03,4.03 10.7,7.37"
            />
            <line
                fill="none"
                stroke={color}
                strokeWidth="1.3333"
                strokeMiterlimit="10"
                x1="3.7"
                y1="4.07"
                x2="13.7"
                y2="4.07"
            />
        </svg>
    );
};

ExternalIcon.propTypes = propTypes;
ExternalIcon.defaultProps = defaultProps;

export default ExternalIcon;
