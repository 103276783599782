import React, { useContext, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import Bowser from 'bowser';

const SiteContext = React.createContext(null);

export const useSite = () => useContext(SiteContext);

export const usePerspective = () => {
    const { perspective } = useSite();
    return perspective;
};

export const useSetPerspective = () => {
    const { setPerspective } = useSite();
    return setPerspective;
};

export const useSafariException = () => {
    const { oldSafari } = useSite();
    return oldSafari;
};

const propTypes = {
    children: PropTypes.node.isRequired,
    perspective: PropTypes.bool,
    oldSafari: PropTypes.bool,
};

const defaultProps = {
    perspective: false,
    oldSafari: false,
};

export const SiteProvider = ({
    children,
    oldSafari: initialOldSafari,
    perspective: initialPerspective,
}) => {
    const [perspective, setPerspective] = useState(initialPerspective);
    const oldSafari =
        useMemo(() => {
            const browser = Bowser.getParser(window.navigator.userAgent);
            return browser.satisfies({
                safari: '<=13',
            });
        }, []) || initialOldSafari;
    return (
        <SiteContext.Provider value={{ perspective, setPerspective, oldSafari }}>
            {children}
        </SiteContext.Provider>
    );
};

SiteProvider.propTypes = propTypes;
SiteProvider.defaultProps = defaultProps;

export default SiteContext;
