/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from '../../styles/partials/html.module.scss';

const propTypes = {
    body: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    body: 'Quiz de personalité',
    className: null,
};

const HtmlPartial = ({ body, className }) => (
    <div
        className={classNames([styles.container, { [className]: className !== null }])}
        dangerouslySetInnerHTML={{ __html: body }}
    />
);

HtmlPartial.propTypes = propTypes;
HtmlPartial.defaultProps = defaultProps;

export default HtmlPartial;
