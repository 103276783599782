/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

const propTypes = {
    title: PropTypes.string,
};

const defaultProps = {
    title: 'Test de Personali.tv | URBANIA x TOU.TV',
};

const PageMetaPartial = ({ title }) => (
    <Helmet>
        <title>{title}</title>
    </Helmet>
);

PageMetaPartial.propTypes = propTypes;
PageMetaPartial.defaultProps = defaultProps;

export default PageMetaPartial;
