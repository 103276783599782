/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Button from './Button';
import ArrowIcon from '../icons/Arrow';

import styles from '../../styles/buttons/pink.module.scss';

const propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
    withIcon: PropTypes.bool,
};

const defaultProps = {
    className: null,
    children: null,
    withIcon: false,
};

const PinkButton = ({ className, children, withIcon, ...props }) => (
    <Button
        className={classNames([styles.container, { [className]: className !== null }])}
        {...props}
    >
        <div className={styles.inner}>
            {children}
            {withIcon ? <ArrowIcon className={styles.arrow} /> : null}
        </div>
    </Button>
);

PinkButton.propTypes = propTypes;
PinkButton.defaultProps = defaultProps;

export default PinkButton;
