import { gsap, TimelineLite as Timeline } from 'gsap';

// Cheating webpack css plugin gsap on build
const all = [gsap]; // eslint-disable-line

// export const getFadeTimeline = (node, delay) => {
//     const timeline = new Timeline({ paused: true });
//     timeline.fromTo(node, { autoAlpha: 0, delay }, { autoAlpha: 1, delay });
//     return timeline;
// };

export const getDisplayFadeInTimeline = (node, delay) => {
    const timeline = new Timeline({ paused: true });
    timeline.fromTo(node, { autoAlpha: 0, delay }, { autoAlpha: 1, duration: 1, delay });
    return timeline;
};

export const getDisplayFadeOutTimeline = (node, delay) => {
    const timeline = new Timeline({ paused: true });
    timeline.fromTo(node, { autoAlpha: 1, delay }, { autoAlpha: 0, duration: 1, delay });
    return timeline;
};

export const getRevealTimeline = (node, delay) => {
    const timeline = new Timeline({ paused: true });
    timeline.fromTo(node, { autoAlpha: 0, delay }, { autoAlpha: 1, duration: 1, delay });
    return timeline;
};

// export const getDefaultTimeline = () => {
//     const timeline = new Timeline({ paused: true });
//     // const texts = node.querySelectorAll('h1 > div');

//     // timeline
//     //     .from(node, 0, { display: 'none', autoAlpha: 0, delay })
//     //     .staggerFrom(texts, 0.375, { autoAlpha: 0, x: -25, ease: Power1.easeOut }, 0.125);

//     return timeline;
// };

// export const page = (pathname, node, appears) => {
//     const delay = appears ? 0 : 1;
//     let timeline;

//     if (pathname === '/') timeline = getFadeTimeline(node, delay);
//     // Home
//     else timeline = getFadeTimeline(node, delay);

//     timeline.play();
// };

export const enter = (node, appears) => {
    const delay = appears ? 0 : 2;
    const timeline = getDisplayFadeInTimeline(node, delay);
    timeline.play();
};

export const exit = (node, appears) => {
    const delay = appears ? 0 : 2;
    const timeline = getDisplayFadeOutTimeline(node, delay);
    timeline.play();
};

export const enterPage = (node, appears) => {
    const delay = appears ? 0 : 0.3;
    const timeline = getDisplayFadeInTimeline(node, delay);
    timeline.play();
};

// export const exitPage = (node, appears) => {
//     const delay = appears ? 0 : 0.3;
//     const timeline = getDisplayFadeOutTimeline(node, delay);
//     timeline.play();
// };

export const reveal = (node, appears, delay = 1) => {
    const timeline = getRevealTimeline(node, delay);
    timeline.play();
};
