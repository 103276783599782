import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from '../../styles/icons/default.module.scss';

const propTypes = {
    color: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    color: '#FFF',
    className: null,
};

const UrbaniaIcon = ({ color, className }) => {
    return (
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            width="110px"
            height="38.8px"
            viewBox="0 0 110 38.8"
            className={classNames([styles.container, { [className]: className !== null }])}
            xmlSpace="preserve"
        >
            <g>
                <path
                    fill={color}
                    d="M41.3,18.6c0-0.2,1.5-0.5,2.5-1.8c0.7-1,1.1-2,1.1-3.8c0-2,0-2.6,0-4.7c0-2.3-0.6-4.5-2.2-6
			c-1.7-1.6-4.1-2.3-6.6-2.3c-1.5,0-4.2,0-5.5,0c-0.6,0-1-0.1-1.3,0.3C29,0.6,29,1.1,29,1.8c0,10.7,0,24.5,0,35.1
			c0,1.2,0.1,1.3,0.3,1.7c0.2,0.3,0.9,0.2,1.7,0.3c1.3,0.1,3.7,0,5.4,0c2.2,0,4.7-0.6,6.5-2.5c1.8-1.9,2.3-3.9,2.3-5.9
			c0-2.5,0-2.4,0-6.4c0-1.5-0.2-2.4-0.9-3.4C43.1,19,41.3,18.8,41.3,18.6z M34.9,6.9c0-0.3,0.1-0.6,0.2-0.8c0.2-0.2,0.6-0.2,1.3-0.2
			c1,0,1.4,0.2,1.8,0.7c0.7,0.8,0.8,1.9,0.8,3c0,1.3,0,2.1,0,3.8c0,0.6-0.1,1.4-0.8,2.2c-0.6,0.7-1.4,0.8-2,0.8
			c-0.4,0-0.7,0.1-1-0.2c-0.3-0.2-0.3-0.6-0.3-1.1C34.9,12.7,34.9,9.3,34.9,6.9z M39.2,29.8c0,1-0.1,1.7-0.8,2.4
			c-0.6,0.7-1.5,0.8-2,0.8c-0.6,0-0.8,0-1.1-0.1c-0.4-0.1-0.4-0.7-0.4-1.2c0-2.8,0-6.9,0-9.1c0-1,0.2-1.1,2-1.1
			c1.6,0,2.3,1.4,2.3,3.5C39.2,26.7,39.2,28.2,39.2,29.8z"
                />
                <path
                    fill={color}
                    d="M57.6,1c-0.2-0.9-1.7-1-3-1c-1.2,0-2.8,0.1-2.9,0.8c-1.8,13.3-3.3,23-5.3,37.5c-0.1,0.5,1.9,0.5,2.7,0.5
			c1.8,0,3,0.1,3-0.6c0.3-3.2,0.5-5.3,0.8-8.4c0.1-0.6,0.6-0.4,1.7-0.4c0.9,0,1.5-0.1,1.6,0.4c0.4,3,0.7,5.1,1.1,8.2
			c0.1,0.8,1.2,0.7,2.8,0.7c1.5,0,3.2,0.1,3-0.6C60.9,23.8,59.7,15.2,57.6,1z M54.6,25.3c-0.8,0-1.1-0.1-1.1-0.4
			c0.3-3.5,0.5-6.3,0.8-10.6c-0.1-0.2,0.1-1.6,0.3-1.6c0.4,0,0.5,1.2,0.5,1.5c0.3,4.4,0.4,6.7,0.7,10.8
			C55.8,25.4,55.3,25.3,54.6,25.3z"
                />
                <path
                    fill={color}
                    d="M79.2,0.1c-2.8,0-2.9,0.1-2.9,1c0,6.4,0,11,0,16.9c0,0.6,0,1.7-0.4,1.7c-0.3,0-0.5-0.9-0.7-1.4
			c-1.7-5.9-3.3-11.8-4.5-16.3c-0.4-1.5-0.7-1.8-3.1-1.8c-2.4,0-2.5,0-2.5,0.8c0,13.2,0,23.2,0,37c0,0.8,0,0.9,2.8,0.9
			c2.9,0,3-0.1,3-1c0-7.6,0-11,0-18.2c0-0.7,0-1.9,0.4-1.9c0.4,0,0.8,2,1.2,3.3c1.9,7,2.6,9.9,4.4,16.3c0.4,1.4,1,1.5,2.4,1.5
			c2.8,0,2.8-0.2,2.8-1.6c0-13.8,0-27.2,0-36.2C82.1,0.1,82,0.1,79.2,0.1z"
                />
                <path
                    fill={color}
                    d="M88.2,0.1c-2.6,0-2.8,0.1-2.8,0.9c0,13.3,0,23.1,0,37c0,0.8,0.1,0.8,2.8,0.8c3,0,3.1-0.1,3.1-0.9
			c0-14.1,0-27.9,0-36.8C91.3,0.2,91.1,0.1,88.2,0.1z"
                />
                <path
                    fill={color}
                    d="M110,38.2c-2.2-14.4-3.4-23.1-5.5-37.2c-0.2-0.9-1.7-1-3-1c-1.2,0-2.8,0.1-2.9,0.8c-1.8,13.3-3.3,23-5.3,37.5
			c-0.1,0.5,1.9,0.5,2.7,0.5c1.8,0,3,0.1,3-0.6c0.3-3.2,0.5-5.3,0.8-8.4c0.1-0.6,0.6-0.4,1.7-0.4c0.9,0,1.5-0.1,1.6,0.4
			c0.4,3,0.7,5.1,1.1,8.2c0.1,0.8,1.2,0.7,2.8,0.7C108.4,38.8,110.1,38.8,110,38.2z M101.4,25.3c-0.8,0-1.1-0.1-1.1-0.4
			c0.3-3.5,0.5-6.3,0.8-10.6c-0.1-0.2,0.1-1.6,0.3-1.6c0.4,0,0.5,1.2,0.5,1.5c0.3,4.4,0.4,6.7,0.7,10.8
			C102.7,25.4,102.1,25.3,101.4,25.3z"
                />
                <path
                    fill={color}
                    d="M22.4,18.8c0-0.2,1.7-0.5,2.7-2c0.7-1,0.9-2,0.9-3.7c0-2,0-2.5,0-4.7c0-2.3-0.6-4.5-2.3-6.1
			c-1.7-1.6-4.1-2.3-6.5-2.3c-1.5,0-4.2,0-5.5,0c-0.7,0-1.1-0.1-1.4,0.3c-0.2,0.3-0.2,0.8-0.2,1.4c0,2.7-0.1,22.9-0.1,28.1
			c0,1.5-0.3,3.1-2.1,3.1c-1.8,0-2.1-1.6-2.1-3.1c0-7.9,0-19.6,0-28.8c0-0.8-0.6-0.9-2.8-0.9C0.4,0.1,0,0.1,0,1c0,10.2,0,21,0,30.1
			c0,4,3,7.7,8,7.7c5.1,0,8.1-3.2,8.1-7.7c0-2.1,0-4.4,0-6.8c0-0.6,0-1.2,0-1.9c0-0.8,0.2-0.7,2.2-0.7c1.4,0,2,1.5,2,3.5
			c0,2.5,0,8.2,0,12.7c0,1,0.2,0.9,2.9,0.9s3,0,3-0.9c0-4.9,0-8.8,0-13.9c0-1.5-0.3-2.5-1-3.5C24.2,19.1,22.4,19,22.4,18.8z
			 M20.1,13.3c0,0.6-0.1,1.4-0.8,2.2c-0.6,0.7-1.5,0.8-2,0.8c-0.4,0-0.8,0.1-1-0.1c-0.3-0.2-0.3-0.6-0.3-1.1c0-2.2,0-2.8,0-4.8
			c0-0.8,0-1.9,0-3.5c0-0.3,0.1-0.5,0.2-0.7c0.2-0.3,0.6-0.3,1.1-0.3c1.3,0,1.6,0.2,2,0.7c0.7,0.8,0.8,1.9,0.8,3
			C20.1,10.8,20.1,12,20.1,13.3z"
                />
            </g>
        </svg>
    );
};

UrbaniaIcon.propTypes = propTypes;
UrbaniaIcon.defaultProps = defaultProps;

export default UrbaniaIcon;
