import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

// import { TrackingContainer } from '@folklore/tracking';
import { IntlProvider } from 'react-intl';
import { BrowserRouter } from 'react-router-dom';

import { RoutesProvider } from './contexts/RoutesContext';

import { KeysProvider } from './contexts/KeysContext';
import { PagesProvider } from './contexts/PagesContext';
import { ResultsProvider } from './contexts/ResultsContext';
import { SiteProvider } from './contexts/SiteContext';

import App from './App';

const propTypes = {
    locale: PropTypes.string,
    messages: PropTypes.oneOfType([
        PropTypes.objectOf(PropTypes.objectOf(PropTypes.string)),
        PropTypes.objectOf(PropTypes.string),
    ]),
    routes: PropTypes.objectOf(PropTypes.string),
    pages: PropTypes.objectOf(PropTypes.object),
    shows: PropTypes.objectOf(PropTypes.object),
    profiles: PropTypes.arrayOf(PropTypes.object),
    googleApiKey: PropTypes.string,
};

const defaultProps = {
    locale: 'fr',
    messages: {},
    routes: {},
    pages: {},
    shows: {},
    profiles: [],
    googleApiKey: null,
};

const Root = ({ locale, messages, routes, pages, shows, profiles, googleApiKey }) => {
    const keys = useMemo(
        () => ({
            googleApiKey,
        }),
        [googleApiKey],
    );

    const mappedShows = useMemo(
        () =>
            Object.keys(shows).reduce((acc, name) => {
                const show = shows[name];
                acc[name] = {
                    ...show,
                    slug: name,
                };
                return acc;
            }, {}),
        [shows],
    );

    const mappedPages = useMemo(
        () =>
            Object.keys(pages).reduce((acc, name) => {
                const page = pages[name];
                const nextIndex = page.index + 1;
                const nextPage = Object.keys(pages)
                    .map((p) => (pages[p].index === nextIndex ? p : null))
                    .filter((p) => p !== null);
                acc[name] = {
                    ...page,
                    next: nextPage.length > 0 ? nextPage[0] : null,
                    answers: page.answers
                        ? page.answers.map((ans) => ({
                              ...ans,
                              show:
                                  mappedShows[Object.keys(shows).find((s) => s === ans.show)] ||
                                  null,
                          }))
                        : null,
                };
                return acc;
            }, {}),
        [pages, mappedShows],
    );

    const mappedProfiles = useMemo(
        () =>
            profiles.map((profile) => {
                return {
                    ...profile,
                    advice: profile.advice.map((name) => mappedShows[name]),
                };
            }, {}),
        [profiles, mappedShows],
    );

    return (
        <IntlProvider locale={locale} messages={messages[locale] || messages}>
            <BrowserRouter>
                <RoutesProvider routes={routes}>
                    <KeysProvider keys={keys}>
                        <PagesProvider pages={mappedPages}>
                            <SiteProvider>
                                <ResultsProvider shows={mappedShows} profiles={mappedProfiles}>
                                    <App />
                                </ResultsProvider>
                            </SiteProvider>
                        </PagesProvider>
                    </KeysProvider>
                </RoutesProvider>
            </BrowserRouter>
        </IntlProvider>
    );
};

Root.propTypes = propTypes;
Root.defaultProps = defaultProps;

export default Root;
