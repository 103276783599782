import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from '../../styles/icons/title.module.scss';

const propTypes = {
    className: PropTypes.string,
};

const defaultProps = {
    className: null,
};

const TitleIcon = ({ className }) => {
    return (
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            width="1078.6px"
            height="157.3px"
            viewBox="0 0 1078.6 157.3"
            className={classNames([styles.container, { [className]: className !== null }])}
            xmlSpace="preserve"
        >
            <g>
                <g>
                    <g>
                        <polygon
                            fill="#660099"
                            points="540.1,76.3 540.1,24.3 510.3,24.3 510.3,8.5 516.1,2.7 595.6,2.7 595.6,18.5 589.8,24.3 565.8,24.3
				565.8,70.5 560,76.3 			"
                        />
                        <path
                            fill="#FFCCCC"
                            d="M594.6,3.6v14.5l-5.2,5.2h-22.7h-1.9v1.9v44.8l-5.2,5.2h-18.5V25.3v-1.9h-1.9h-27.9V8.9l5.2-5.2H594.6
				 M596.5,1.7h-80.8l-6.4,6.4v17.2h29.8v52h21.2l6.4-6.4V25.3h23.5l6.4-6.4V1.7L596.5,1.7z"
                        />
                    </g>
                    <g>
                        <polygon
                            fill="#660099"
                            points="324.6,76.3 324.6,8.5 330.4,2.7 404.8,2.7 404.8,17.4 399,23.2 350.3,23.2 350.3,29.8 401.9,29.8
				401.9,42.8 396.1,48.6 350.3,48.6 350.3,55.2 406,55.2 406,70.5 400.2,76.3 			"
                        />
                        <path
                            fill="#FFCCCC"
                            d="M403.8,3.6V17l-5.2,5.2h-47.3h-1.9v1.9v4.7v1.9h1.9h49.7v11.6l-5.2,5.2h-44.5h-1.9v1.9v4.7v1.9h1.9h53.8
				v13.9l-5.2,5.2h-74.2V8.9l5.2-5.2H403.8 M405.7,1.7H330l-6.4,6.4v69.2h76.9l6.4-6.4V54.3h-55.7v-4.7h45.2l6.4-6.4V28.9h-51.6
				v-4.7h48.1l6.4-6.4V1.7L405.7,1.7z"
                        />
                    </g>
                    <g>
                        <path
                            fill="#660099"
                            d="M650.3,76.3V8.5l5.8-5.8h63.6c20.2,0,27.7,6.3,27.7,23.1v21c0,10.2-2.7,16.2-8.7,19.6l-0.2,0.1l-0.1,0.2
				c-3.6,6.6-11.3,9.5-25,9.5H650.3z M675.9,54.1h38.6c2.4,0,4.3-0.2,5.9-0.6l0.6-0.1l0.1-0.6c0.4-1.6,0.6-3.5,0.6-5.8v-9.4
				c0-9.9-3.6-13.4-13.6-13.4h-32.2V54.1z"
                        />
                        <path
                            fill="#FFCCCC"
                            d="M719.7,3.6c19.8,0,26.8,5.8,26.8,22.2v21c0,9.8-2.5,15.6-8.2,18.7l-0.5,0.3l-0.3,0.5c-3.4,6.2-10.9,9-24.2,9
				h-62.1V8.9l5.2-5.2H719.7 M675,55h1.9h37.7c2.5,0,4.5-0.2,6.1-0.6l1.1-0.3l0.3-1.1c0.4-1.7,0.6-3.6,0.6-6v-9.4
				c0-10.4-3.9-14.3-14.5-14.3h-31.3H675v1.9v27.9V55 M719.7,1.7h-64l-6.4,6.4v69.2h64c14,0,22-3,25.8-10c6.6-3.7,9.2-10.3,9.2-20.4
				v-21C748.4,8.7,740.9,1.7,719.7,1.7L719.7,1.7z M676.9,53.1V25.3h31.3c9.1,0,12.6,2.6,12.6,12.4v9.4c0,2.2-0.2,4-0.6,5.5
				c-1.6,0.4-3.5,0.5-5.7,0.5H676.9L676.9,53.1z"
                        />
                    </g>
                    <g>
                        <polygon
                            fill="#660099"
                            points="263.4,76.3 263.4,24.3 233.6,24.3 233.6,8.5 239.4,2.7 318.9,2.7 318.9,18.5 313.1,24.3 289.1,24.3
				289.1,70.5 283.3,76.3 			"
                        />
                        <path
                            fill="#FFCCCC"
                            d="M317.9,3.6v14.5l-5.2,5.2H290h-1.9v1.9v44.8l-5.2,5.2h-18.5V25.3v-1.9h-1.9h-27.9V8.9l5.2-5.2H317.9
				 M319.8,1.7H239l-6.4,6.4v17.2h29.8v52h21.2l6.4-6.4V25.3h23.5l6.4-6.4V1.7L319.8,1.7z"
                        />
                    </g>
                    <g>
                        <path
                            fill="#660099"
                            d="M440.2,77.1c-20,0-26.1-4.1-26.1-17.7V55l5.8-5.8h10.6l-6.5-1.9c-8.4-2.4-9.8-7.2-9.8-17.9
				c0-8.3,0.5-14.5,7.6-18l0.3-0.2l0.1-0.3c1.9-4.2,6.6-9.1,25.3-9.1h29.6c24,0,25.8,8.1,25.8,19.5v1.8l-5.8,5.8h-8.8l6.6,1.9
				c8.2,2.3,10.9,7.2,10.9,19.1c0,10.4-2.1,15.2-7.6,17.8l-0.3,0.2l-0.1,0.3c-3.3,7.6-12.5,8.9-31.8,8.9H440.2z M439.9,50.3
				c0.7,6.3,4.6,6.8,10,6.8h26c1.1,0,2,0,2.8-0.1l0.9-0.1l0-0.9c-0.2-6.5-4.6-6.7-30.8-6.7c-2.9,0-5.5,0-7.9-0.1l-1.1,0L439.9,50.3z
				 M468,28.9c3.1,0,5.8,0,8.2,0.1l1.1,0l-0.1-1.1c-0.4-4.6-3.1-6.1-11.2-6.1h-23.5c-0.6,0-1.2,0-1.7,0.1l-0.9,0.1l0.1,0.9
				c0.4,5.4,4.3,5.9,20.6,5.9c1.7,0,3.6,0,5.7,0L468,28.9z"
                        />
                        <path
                            fill="#FFCCCC"
                            d="M477.1,2.9c10.8,0,17.7,1.7,21.2,5.1c3,3,3.6,7.4,3.6,13.4v1.4l-5.2,5.2h-1.6h-13.7l13.1,3.7
				c7.7,2.2,10.2,6.6,10.2,18.2c0,10-1.9,14.5-7.1,16.9l-0.7,0.3l-0.3,0.7c-3.1,7.1-12,8.3-30.9,8.3h-25.7
				c-19.5,0-25.1-3.8-25.1-16.7v-4l5.2-5.2h3.4h13.6l-13-3.7c-7.7-2.2-9.1-6.4-9.1-16.9c0-8,0.5-13.9,7.1-17.2l0.6-0.3l0.3-0.6
				c2.7-5.9,10.2-8.5,24.4-8.5H477.1 M460.5,29.8c1.7,0,3.6,0,5.6,0c0.6,0,1.3,0,1.9,0c3.1,0,5.8,0,8.2,0.1l2.1,0l-0.2-2.1
				c-0.5-5.8-4.6-6.9-12.2-6.9h-23.5c-0.6,0-1.2,0-1.8,0.1l-1.9,0.2l0.1,1.9C439.4,29.8,445.6,29.8,460.5,29.8 M449.9,58.1h26
				c1.1,0,2,0,2.9-0.1l1.8-0.2l-0.1-1.8c-0.3-7.6-6.3-7.6-31.7-7.6c-2.9,0-5.5,0-7.9-0.1l-2.2-0.1l0.2,2.2
				C439.7,58.1,445.4,58.1,449.9,58.1 M477.1,1h-29.6c-16.7,0-23.4,3.8-26.1,9.6c-7.6,3.9-8.1,10.8-8.1,18.9
				c0,10.7,1.4,16.2,10.5,18.8h-4.2l-6.4,6.4v4.8c0,15,7.7,18.6,27,18.6h25.7c20.3,0,29.2-1.6,32.7-9.4c6.3-2.9,8.2-8.4,8.2-18.6
				c0-11.9-2.7-17.5-11.6-20h2.4l6.4-6.4v-2.2C503.9,9.3,501.6,1,477.1,1L477.1,1z M460.5,27.9c-16.3,0-19.3-0.6-19.6-5.1
				c0.5,0,1.1-0.1,1.6-0.1H466c8.4,0,10,1.6,10.3,5.2c-2.5-0.1-5.3-0.1-8.2-0.1c-0.6,0-1.3,0-1.9,0
				C464.1,27.9,462.2,27.9,460.5,27.9L460.5,27.9z M449.9,56.2c-5.5,0-8.5-0.5-9.1-6c2.4,0.1,5.1,0.1,8,0.1
				c26.3,0,29.6,0.4,29.8,5.8c-0.9,0.1-1.8,0.1-2.7,0.1H449.9L449.9,56.2z"
                        />
                    </g>
                    <g>
                        <polygon
                            fill="#660099"
                            points="756.7,76.3 756.7,8.5 762.5,2.7 836.9,2.7 836.9,17.4 831.1,23.2 782.4,23.2 782.4,29.8 834,29.8
				834,42.8 828.2,48.6 782.4,48.6 782.4,55.2 838.1,55.2 838.1,70.5 832.3,76.3 			"
                        />
                        <path
                            fill="#FFCCCC"
                            d="M835.9,3.6V17l-5.2,5.2h-47.3h-1.9v1.9v4.7v1.9h1.9H833v11.6l-5.2,5.2h-44.5h-1.9v1.9v4.7v1.9h1.9h53.8v13.9
				l-5.2,5.2h-74.2V8.9l5.2-5.2H835.9 M837.8,1.7h-75.7l-6.4,6.4v69.2h76.9l6.4-6.4V54.3h-55.7v-4.7h45.2l6.4-6.4V28.9h-51.6v-4.7
				h48.1l6.4-6.4V1.7L837.8,1.7z"
                        />
                    </g>
                    <g>
                        <polygon
                            fill="#660099"
                            points="881.5,154.7 881.5,136.5 887.3,130.7 906,130.7 906,148.9 900.2,154.7 			"
                        />
                        <path
                            fill="#FFCCCC"
                            d="M905.1,131.7v16.8l-5.2,5.2h-17.4v-16.8l5.2-5.2H905.1 M907,129.8h-20.1l-6.4,6.4v19.5h20.1l6.4-6.4V129.8
				L907,129.8z"
                        />
                    </g>
                    <g>
                        <path
                            fill="#660099"
                            d="M847.2,154.7v-8l-6.7,8h-67.5V86.8l5.8-5.8h19.9v51.4h48.6V86.8L853,81h19.9v67.8l-5.8,5.8H847.2z
				 M746.2,154.7l-6.6-12.2h-41l-3.2,6.3l-6,6h-21.7L704.3,87l6-6H737l35.5,64.6l-3.9,9H746.2z M708.7,123.1h20.6l-10.4-19.7
				L708.7,123.1z M630.9,154.7l-35.1-44.3v38.5l-5.8,5.8h-19.9V86.8L576,81h32l35.1,44.3V86.8l5.8-5.8h19.9v67.7l-5.8,5.9H630.9z"
                        />
                        <path
                            fill="#FFCCCC"
                            d="M871.9,82v66.5l-5.2,5.2h-18.5v-4.4V144l-3.4,4l-4.8,5.7h-66.1v-4.4v-9.2V87.2l5.2-5.2h18.5v49.5v1.9h1.9
				h46.7h1.9v-1.9V87.2l5.2-5.2H871.9 M736.4,82l33.9,61.7l1.1,2.1l-1.2,2.8l-2.3,5.2h-21.2l-6.1-11.2l-0.5-1H739h-39.9H698l-0.5,1
				l-2.8,5.6l-5.6,5.6h-19.7l1.9-3.5l6.3-11.7l27.6-50.9l5.6-5.6H736.4 M707.2,124h3.1h17.4h3.2l-1.5-2.8l-8.8-16.7l-1.7-3.2
				l-1.7,3.3l-8.6,16.7L707.2,124 M667.8,82v66.3l-5.2,5.4h-31.1l-33.1-41.8l-3.4-4.3v5.5v35.3l-5.2,5.2h-18.5V87.2l5.2-5.2h31.2
				l33.1,41.8l3.4,4.3v-5.5V87.2l5.2-5.2H667.8 M873.8,80.1h-21.2l-6.4,6.4v45.1h-46.7V80.1h-21.2l-6.4,6.4v56.3l-34.4-62.7h-27.6
				l-6.4,6.4L669.7,149V80.1h-21.2l-6.4,6.4v36.1l-33.7-42.5h-32.9l-6.4,6.4v69.2h21.2l6.4-6.4v-36.1l33.7,42.5h32.9l6.2-6.4
				l-3.4,6.4h23.7l6.4-6.4l3-5.9H739l6.6,12.2h23.6l2.8-6.4v6.4h68.9l5.3-6.4v6.4h21.2l6.4-6.4V80.1L873.8,80.1z M710.3,122.1
				l8.6-16.7l8.8,16.7H710.3L710.3,122.1z"
                        />
                    </g>
                    <g>
                        <polygon
                            fill="#660099"
                            points="527.3,154.7 492.2,110.4 492.2,148.9 486.4,154.7 466.5,154.7 466.5,86.8 472.3,81 504.3,81
				539.4,125.3 539.4,86.8 545.2,81 565.1,81 565.1,148.9 559.3,154.7 			"
                        />
                        <path
                            fill="#FFCCCC"
                            d="M564.1,82v66.5l-5.2,5.2h-31.2l-33.1-41.8l-3.4-4.3v5.5v35.3l-5.2,5.2h-18.5V87.2l5.2-5.2h31.2l33.1,41.8
				l3.4,4.3v-5.5V87.2l5.2-5.2H564.1 M566,80.1h-21.2l-6.4,6.4v36.1l-33.7-42.5h-32.9l-6.4,6.4v69.2h21.2l6.4-6.4v-36.1l33.7,42.5
				h32.9l6.4-6.4V80.1L566,80.1z"
                        />
                    </g>
                    <g>
                        <polygon
                            fill="#660099"
                            points="1007.8,154.7 973.9,91.4 973.9,96.3 967.5,96.3 967.5,102.7 944,102.7 944,148.9 938.2,154.7
				918.4,154.7 918.4,102.7 888.6,102.7 888.6,86.8 894.4,81 999.1,81 1025.2,130.7 1048.2,87 1054.1,81 1075.4,81 1039,148.7
				1033,154.7 			"
                        />
                        <path
                            fill="#FFCCCC"
                            d="M1073.8,82l-35.5,66.1l-5.6,5.6h-24.2l-31.9-59.4l-3.6-6.7v7.6v0.1h-4.4h-1.9v1.9v4.4H945h-1.9v1.9v44.8
				l-5.2,5.2h-18.5v-50.1v-1.9h-1.9h-27.9V87.2l5.2-5.2h80.1h1.6h22.1l25,47.5l1.7,3.2l1.7-3.2l22.1-41.9l5.6-5.6H1073.8 M1077,80.1
				h-23.2l-6.4,6.4l-22.2,42.2l-25.5-48.5h-23.2h-1.6H894l-6.4,6.4v17.2h29.8v52h21.2l6.4-6.4v-45.6h23.5v-6.4h6.4v-2l32.4,60.4
				h26.2l6.4-6.4L1077,80.1L1077,80.1z"
                        />
                    </g>
                    <g>
                        <path
                            fill="#660099"
                            d="M393,155.4c-18.2,0-29.5-4-29.5-24.1v-20.6c0-10.2,2.9-16.6,9-20.2l0.2-0.1l0.1-0.2c4-6.9,11.9-9.9,26.5-9.9
				h34c18.2,0,29.5,4,29.5,24.1V125c0,10.2-2.9,16.6-9,20.2l-0.2,0.1l-0.1,0.2c-4,6.9-11.9,9.9-26.5,9.9H393z M397.6,102.5
				c-2.5,0-4.6,0.3-6.5,0.8l-0.5,0.1l-0.1,0.5c-0.5,1.9-0.8,4.1-0.8,7v7.5c0,12.5,4.4,14.8,13.1,14.8h27.1c2.1,0,3.9-0.2,5.4-0.5
				l0.6-0.1l0.1-0.6c0.5-1.9,0.7-4.2,0.7-7.2v-7.5c0-12.3-5.7-14.8-14.2-14.8H397.6z"
                        />
                        <path
                            fill="#FFCCCC"
                            d="M433.4,81.2c10.3,0,16.9,1.4,21.3,4.6c5,3.5,7.3,9.4,7.3,18.6V125c0,9.8-2.7,16-8.6,19.4l-0.4,0.3l-0.3,0.4
				c-3.8,6.6-11.4,9.4-25.7,9.4h-34c-10.3,0-16.9-1.4-21.3-4.6c-5-3.5-7.3-9.4-7.3-18.6v-20.6c0-9.8,2.7-16,8.6-19.4l0.4-0.3
				l0.3-0.4c3.8-6.6,11.4-9.4,25.7-9.4H433.4 M402.8,134.2h27.1c2.2,0,4-0.2,5.6-0.5l1.1-0.3l0.3-1.1c0.5-1.9,0.7-4.3,0.7-7.5v-7.5
				c0-8.2-1.8-15.8-15.2-15.8h-24.8c-2.6,0-4.8,0.3-6.7,0.9l-1,0.3l-0.3,1c-0.6,1.9-0.8,4.2-0.8,7.3v7.5
				C388.8,128.6,391,134.2,402.8,134.2 M433.4,79.3h-34c-13.2,0-22.7,2.3-27.3,10.4c-6.1,3.6-9.5,10-9.5,21v20.6
				c0,20.3,11.4,25,30.5,25h34c13.2,0,22.7-2.3,27.3-10.4c6.1-3.6,9.5-10,9.5-21v-20.6C463.9,84.1,452.5,79.3,433.4,79.3L433.4,79.3
				z M402.8,132.3c-10,0-12.1-3.8-12.1-13.9v-7.5c0-2.6,0.2-4.8,0.7-6.7c1.7-0.5,3.7-0.8,6.2-0.8h24.8c11.2,0,13.3,5.3,13.3,13.9
				v7.5c0,2.8-0.2,5.1-0.6,7c-1.4,0.3-3.1,0.5-5.1,0.5H402.8L402.8,132.3z"
                        />
                    </g>
                    <g>
                        <polygon
                            fill="#660099"
                            points="91.4,154.7 91.4,86.8 97.2,81 171.6,81 171.6,95.7 165.8,101.5 117.1,101.5 117.1,108.2
				168.7,108.2 168.7,121.2 162.9,126.9 117.1,126.9 117.1,133.6 172.8,133.6 172.8,148.9 167,154.7 			"
                        />
                        <path
                            fill="#FFCCCC"
                            d="M170.6,82v13.4l-5.2,5.2h-47.3h-1.9v1.9v4.7v1.9h1.9h49.7v11.6l-5.2,5.2h-44.5h-1.9v1.9v4.7v1.9h1.9h53.8
				v13.9l-5.2,5.2H92.4V87.2l5.2-5.2H170.6 M172.5,80.1H96.8l-6.4,6.4v69.2h76.9l6.4-6.4v-16.6h-55.7v-4.7h45.2l6.4-6.4v-14.3h-51.6
				v-4.7h48.1l6.4-6.4V80.1L172.5,80.1z"
                        />
                    </g>
                    <g>
                        <path
                            fill="#660099"
                            d="M1.9,154.7V86.8L7.7,81h57.4c16.9,0,24.1,6.8,24.1,22.7v5.9c0,4.2-0.9,14.5-9.1,19l-0.2,0.1l-0.1,0.2
				c-3.1,5.4-8.3,8.2-15.5,8.2H27.6v11.6l-5.8,5.8H1.9z M27.6,115.6h33.9c0.1,0,0.3,0,0.4,0l1,0l0.1-0.8c0-0.3,0-0.7,0-1.1V111
				c0-5.6-3.2-8.3-9.7-8.3H27.6V115.6z"
                        />
                        <path
                            fill="#FFCCCC"
                            d="M65.1,82c8.9,0,15,2,18.6,6.1c3.1,3.5,4.5,8.4,4.5,15.7v5.9c0,4-0.8,13.9-8.6,18.2l-0.5,0.3l-0.3,0.5
				c-2.9,5.1-7.9,7.7-14.6,7.7H28.5h-1.9v1.9v10.2l-5.2,5.2H2.9V87.2L8.1,82H65.1 M26.6,116.6h1.9h33c0.2,0,0.3,0,0.4,0l0.1,0
				l1.7-0.1l0.1-1.7c0-0.4,0-0.8,0-1.1V111c0-6.2-3.6-9.3-10.6-9.3H28.5h-1.9v1.9v11V116.6 M65.1,80.1H7.3L1,86.4v69.2h21.2l6.4-6.4
				v-11h35.8c8.3,0,13.4-3.7,16.3-8.7c7.1-4,9.6-12.3,9.6-19.8v-5.9C90.2,90.2,85.3,80.1,65.1,80.1L65.1,80.1z M28.5,114.7v-11h24.8
				c6.4,0,8.7,2.7,8.7,7.4v2.7c0,0.3,0,0.7,0,1c-0.2,0-0.3,0-0.5,0H28.5L28.5,114.7z"
                        />
                    </g>
                    <g>
                        <path
                            fill="#660099"
                            d="M239.7,154.7v-7.4c0-7.8-2.5-10.6-9.7-10.6h-28v12.2l-5.8,5.8h-19.9V86.8l5.8-5.8h63.6
				c14,0,19.8,5.7,19.8,19.6v4.4c0,7.1-1.2,12.1-7.2,14.8l-0.4,0.2l-0.1,0.4c-0.3,0.8-0.7,1.5-1.1,2.1l-0.7,1.1l1.2,0.4
				c3.9,1.2,8,3.8,8,13.7v11.4l-5.8,5.8H239.7z M202.1,115h36c0.5,0,1,0,1.5-0.1l0.8-0.1l0-0.8c0-0.3,0-0.6,0-0.9v-2.3
				c0-6.9-3.8-8.2-10.7-8.2h-27.8V115z"
                        />
                        <path
                            fill="#FFCCCC"
                            d="M245.8,82c13.4,0,18.8,5.4,18.8,18.6v4.4c0,8.1-1.7,11.7-6.6,13.9l-0.7,0.3l-0.3,0.7c-0.3,0.7-0.6,1.3-1,1.9
				l-1.5,2.1l2.5,0.8c3.6,1.1,7.3,3.5,7.3,12.8v11l-5.2,5.2h-18.5v-6.4c0-8.3-3-11.6-10.6-11.6h-27h-1.9v1.9v10.8l-5.2,5.2h-18.5
				V87.2l5.2-5.2H245.8 M201.1,116h1.9h35.1c0.5,0,1,0,1.6-0.1l1.7-0.1l0.1-1.7c0-0.3,0-0.6,0-0.9v-2.3c0-8.5-5.8-9.2-11.7-9.2H203
				h-1.9v1.9v10.5V116 M245.8,80.1h-64l-6.4,6.4v69.2h21.2l6.4-6.4v-11.6h27c6.6,0,8.7,2.4,8.7,9.7v8.3h21.2l6.4-6.4v-11.8
				c0-9.6-3.7-13-8.6-14.6c0.5-0.7,0.9-1.5,1.2-2.3c6.2-2.8,7.7-7.9,7.7-15.6v-4.4C266.6,88.2,262.2,80.1,245.8,80.1L245.8,80.1z
				 M203,114.1v-10.5h26.8c6.7,0,9.7,1.1,9.7,7.3v2.3c0,0.3,0,0.6,0,0.8c-0.5,0-0.9,0.1-1.5,0.1H203L203,114.1z"
                        />
                    </g>
                    <g>
                        <path
                            fill="#660099"
                            d="M295.5,155.4c-20,0-26.1-4.1-26.1-17.7v-4.4l5.8-5.8h10.6l-6.5-1.9c-8.4-2.4-9.8-7.2-9.8-17.9
				c0-8.3,0.5-14.5,7.6-18l0.3-0.2l0.1-0.3c1.9-4.2,6.6-9.1,25.3-9.1h29.6c24,0,25.8,8.1,25.8,19.5v1.6c-1.1,0.6-3.8,2.1-6,4
				l-0.3,0.3v1.7h-8.3l6.6,1.9c8.2,2.3,10.9,7.2,10.9,19.1c0,10.4-2.1,15.2-7.6,17.8l-0.3,0.2l-0.1,0.3c-3.3,7.6-12.5,8.9-31.8,8.9
				H295.5z M295.2,128.7c0.7,6.3,4.6,6.8,10,6.8h26c1.1,0,2,0,2.8-0.1l0.9-0.1l0-0.9c-0.2-6.5-4.6-6.7-30.8-6.7
				c-2.9,0-5.5,0-7.9-0.1l-1.1,0L295.2,128.7z M323.4,107.2c3.1,0,5.8,0,8.2,0.1l1.1,0l-0.1-1.1c-0.4-4.6-3.1-6.1-11.2-6.1h-23.5
				c-0.6,0-1.2,0-1.7,0.1l-0.9,0.1l0.1,0.9c0.4,5.4,4.3,5.9,20.6,5.9c1.7,0,3.6,0,5.6,0L323.4,107.2z"
                        />
                        <path
                            fill="#FFCCCC"
                            d="M332.5,81.2c10.8,0,17.7,1.7,21.2,5.1c3,3,3.6,7.4,3.6,13.4v1c-1.3,0.7-3.7,2.1-5.7,3.9l-0.7,0.6v0.9v0.3
				h-0.5h-13.7l13.1,3.7c7.7,2.2,10.2,6.6,10.2,18.2c0,10-1.9,14.5-7.1,16.9l-0.7,0.3l-0.3,0.7c-3.1,7.1-12,8.3-30.9,8.3h-25.7
				c-19.5,0-25.1-3.8-25.1-16.7v-4l5.2-5.2h3.4h13.6l-13-3.7c-7.7-2.2-9.1-6.4-9.1-16.9c0-8,0.5-13.9,7.1-17.2l0.6-0.3l0.3-0.6
				c2.7-5.9,10.2-8.5,24.4-8.5H332.5 M315.9,108.2c1.7,0,3.6,0,5.6,0c0.6,0,1.3,0,1.9,0c3.1,0,5.8,0,8.2,0.1l2.1,0l-0.2-2.1
				c-0.5-5.8-4.6-6.9-12.2-6.9h-23.5c-0.6,0-1.2,0-1.8,0.1l-1.9,0.2l0.1,1.9C294.8,108.2,301,108.2,315.9,108.2 M305.2,136.5h26
				c1.1,0,2,0,2.9-0.1l1.8-0.2l-0.1-1.8c-0.3-7.6-6.3-7.6-31.7-7.6c-2.9,0-5.5,0-7.9-0.1l-2.2-0.1l0.2,2.2
				C295.1,136.5,300.7,136.5,305.2,136.5 M332.5,79.3h-29.6c-16.7,0-23.4,3.8-26.1,9.6c-7.6,3.9-8.1,10.8-8.1,18.9
				c0,10.7,1.4,16.2,10.5,18.8h-4.2l-6.4,6.4v4.8c0,15,7.7,18.6,27,18.6h25.7c20.3,0,29.2-1.6,32.7-9.4c6.3-2.9,8.2-8.4,8.2-18.6
				c0-11.9-2.7-17.5-11.6-20h2.4v-2.2c2.7-2.4,6.4-4.2,6.4-4.2v-2.2C359.2,87.6,356.9,79.3,332.5,79.3L332.5,79.3z M315.9,106.3
				c-16.3,0-19.3-0.6-19.6-5.1c0.5,0,1.1-0.1,1.6-0.1h23.5c8.4,0,10,1.6,10.3,5.2c-2.5-0.1-5.3-0.1-8.2-0.1c-0.6,0-1.2,0-1.9,0
				C319.5,106.3,317.6,106.3,315.9,106.3L315.9,106.3z M305.2,134.5c-5.5,0-8.5-0.5-9.1-6c2.4,0.1,5.1,0.1,8,0.1
				c26.3,0,29.6,0.4,29.8,5.8c-0.9,0.1-1.8,0.1-2.7,0.1H305.2L305.2,134.5z"
                        />
                    </g>
                </g>
                <g>
                    <linearGradient
                        id="SVGID_TITLE_"
                        gradientUnits="userSpaceOnUse"
                        x1="541.8115"
                        y1="1.9112"
                        x2="541.8115"
                        y2="149.0708"
                    >
                        <stop offset="0" stopColor="#00FFB8" />
                        <stop offset="9.677172e-03" stopColor="#00FEB5" />
                        <stop offset="0.2002" stopColor="#00F57E" />
                        <stop offset="0.386" stopColor="#00ED51" />
                        <stop offset="0.5629" stopColor="#00E72E" />
                        <stop offset="0.7286" stopColor="#00E315" />
                        <stop offset="0.8789" stopColor="#00E005" />
                        <stop offset="1" stopColor="#00DF00" />
                    </linearGradient>
                    <path
                        fill="url(#SVGID_TITLE_)"
                        d="M399.4,149.1c-18.2,0-29.5-4-29.5-24.1v-20.6c0-20.1,11.3-24.1,29.5-24.1h34c18.2,0,29.5,4,29.5,24.1V125
			c0,20.1-11.3,24.1-29.5,24.1H399.4z M404,96.1c-11.8,0-14.2,5.9-14.2,14.8v7.5c0,9.8,2,14.8,13.1,14.8h27.1
			c11.1,0,13.1-5,13.1-14.8v-7.5c0-8.9-2.4-14.8-14.2-14.8H404z M301.9,149.1c-20,0-26.1-4.1-26.1-17.7v-3.8h19.3
			c0.4,7.4,4.1,7.9,10.1,7.9h26c3.3,0,10,0,10-6.8c0-7.1-3.5-7.4-30.8-7.4c-31.6,0-34.6-3.5-34.6-19.9c0-11.4,0-21.2,26.9-21.2h29.6
			c24,0,25.8,8.1,25.8,19.5v1.2H339c-0.1-5-1.6-7.2-11.3-7.2h-23.5c-5.7,0-9,2.3-9,6.3c0,6.5,3.4,7.1,20.6,7.1c1.7,0,3.6,0,5.6,0
			l2,0c32.3,0,37.6,3,37.6,21.1c0,17.6-5,20.7-33.5,20.7H301.9z M1014.2,148.3L978,81h21l27.6,52.5l27.6-52.5h21l-36.1,67.3H1014.2z
			 M924.7,148.3v-52h-29.8V81h78.9v15.3H944v52H924.7z M887.9,148.3v-17.6H906v17.6H887.9z M853.5,148.3V81h19.3v67.3H853.5z
			 M779.3,148.3V81h19.3v51.4h47.7v15.9H779.3z M752.5,148.3l-6.6-12.2h-44.2l-6.2,12.2h-21.5L710.5,81H737l37,67.3H752.5z
			 M708.7,123.1H739l-15.4-29L708.7,123.1z M637.3,148.3L597.4,98h-1.6v50.3h-19.3V81H608l39.8,50.3h1.6V81h19.3v67.3H637.3z
			 M533.6,148.3L493.8,98h-1.6v50.3h-19.3V81h31.5l39.8,50.3h1.6V81h19.3v67.3H533.6z M246,148.3v-7.4c0-7.8-2.5-10.6-9.7-10.6
			h-34.3v18h-19.3V81h63.1c14,0,19.8,5.7,19.8,19.6v4.4c0,8.7-1.8,13.9-11.1,16.1l-0.7,0.2v1.7l0.8,0.1c6.5,1.2,10.8,4.1,10.8,14.3
			v10.8H246z M202.1,115h36c6.1,0,8.8-2.5,8.8-8.2v-2.3c0-6.9-3.8-8.2-10.7-8.2h-34.1V115z M97.8,148.3V81h73.8v14.1h-54.5v13h51.6
			v12.4h-51.6v13h55.7v14.7H97.8z M8.3,148.3V81h56.9c9.2,0,15.5,2.1,19.4,6.4c3.2,3.6,4.7,8.8,4.7,16.3v5.9c0,5-1.3,21.2-18.5,21.2
			H27.6v17.4H8.3z M27.6,115.6h33.9c5,0,7.8-3,7.8-8.3v-2.7c0-5.6-3.2-8.3-9.7-8.3H27.6V115.6z M446.5,70.7
			c-20,0-26.1-4.1-26.1-17.7v-3.8h19.3c0.4,7.4,4.1,7.9,10.1,7.9h26c3.3,0,10,0,10-6.8c0-7.1-3.5-7.4-30.8-7.4
			c-31.6,0-34.6-3.5-34.6-19.9c0-11.4,0-21.2,26.9-21.2h29.6c24,0,25.8,8.1,25.8,19.5v1.2h-19.3c-0.1-5-1.6-7.2-11.3-7.2h-23.5
			c-5.7,0-9,2.3-9,6.3c0,6.5,3.4,7.1,20.6,7.1c1.7,0,3.6,0,5.6,0l2,0c32.3,0,37.6,3,37.6,21.1c0,17.6-5,20.7-33.5,20.7H446.5z
			 M763.1,69.9V2.7h73.8v14.1h-54.5v13H834v12.4h-51.6v13h55.7v14.7H763.1z M656.6,69.9V2.7h63.1c20.2,0,27.7,6.3,27.7,23.1v21
			c0,16.9-7.5,23.1-27.7,23.1H656.6z M675.9,54.1h38.6c10,0,13.6-3.5,13.6-13.4v-9.4c0-9.9-3.6-13.4-13.6-13.4h-38.6V54.1z
			 M546.5,69.9V18h-29.8V2.7h78.9V18h-29.8v52H546.5z M331,69.9V2.7h73.8v14.1h-54.5v13h51.6v12.4h-51.6v13H406v14.7H331z
			 M269.7,69.9V18h-29.8V2.7h78.9V18h-29.8v52H269.7z"
                    />
                    <path
                        fill="#FFCCCC"
                        d="M477.1,2.9c23.1,0,24.8,7.3,24.8,18.5v0.3h-17.5c-0.4-5-2.7-7.2-12.2-7.2h-23.5c-9,0-9.9,5.1-9.9,7.3
			c0,7.7,4.8,8,21.6,8c1.7,0,3.6,0,5.7,0c0.7,0,1.4,0,2,0c15,0,24.7,0.6,30.1,3.4c4.8,2.5,6.6,7.1,6.6,16.8c0,9.3-1.6,13.7-5.8,16.2
			c-4.9,3-13.9,3.6-26.8,3.6h-25.7c-19.5,0-25.1-3.8-25.1-16.7v-2.9h17.5c0.7,7.3,5.2,7.9,11,7.9h26c3.3,0,11,0,11-7.7
			c0-8.3-5.5-8.3-31.7-8.3c-16.4,0-24.4-1-28.7-3.7c-3.7-2.3-4.9-6-4.9-15.2c0-5.9,0.3-11,3.4-14.5c3.5-3.9,10.9-5.8,22.6-5.8H477.1
			 M835.9,3.6v12.2h-52.6h-1.9v1.9v11.1v1.9h1.9H833v10.5h-49.7h-1.9v1.9v11.1v1.9h1.9h53.8V69H764V3.6H835.9 M719.7,3.6
			c19.8,0,26.8,5.8,26.8,22.2v21c0,16.4-7,22.2-26.8,22.2h-62.1V3.6H719.7 M675,55h1.9h37.7c10.6,0,14.5-3.9,14.5-14.3v-9.4
			c0-10.4-3.9-14.3-14.5-14.3h-37.7H675v1.9v34.2V55 M594.6,3.6V17h-27.9h-1.9v1.9V69h-17.4V18.9V17h-1.9h-27.9V3.6H594.6
			 M403.8,3.6v12.2h-52.6h-1.9v1.9v11.1v1.9h1.9h49.7v10.5h-49.7h-1.9v1.9v11.1v1.9h1.9h53.8V69H332V3.6H403.8 M317.9,3.6V17H290
			h-1.9v1.9V69h-17.4V18.9V17h-1.9h-27.9V3.6H317.9 M433.4,81.2c10.3,0,16.9,1.4,21.3,4.6c5,3.5,7.3,9.4,7.3,18.6V125
			c0,9.1-2.3,15-7.3,18.6c-4.4,3.2-11,4.6-21.3,4.6h-34c-10.3,0-16.9-1.4-21.3-4.6c-5-3.5-7.3-9.4-7.3-18.6v-20.6
			c0-9.1,2.3-15,7.3-18.6c4.4-3.2,11-4.6,21.3-4.6H433.4 M402.8,134.2h27.1c11.8,0,14-5.5,14-15.8v-7.5c0-8.2-1.8-15.8-15.2-15.8
			H404c-13.4,0-15.2,7.6-15.2,15.8v7.5C388.8,128.6,391,134.2,402.8,134.2 M332.5,81.2c23.1,0,24.8,7.3,24.8,18.5v0.3h-17.5
			c-0.4-5-2.7-7.2-12.2-7.2h-23.5c-9,0-9.9,5.1-9.9,7.3c0,7.7,4.8,8,21.6,8c1.7,0,3.6,0,5.7,0c0.7,0,1.4,0,2,0
			c15,0,24.7,0.6,30.1,3.4c4.8,2.5,6.6,7.1,6.6,16.8c0,9.3-1.6,13.7-5.8,16.2c-4.9,3-13.9,3.6-26.8,3.6h-25.7
			c-19.5,0-25.1-3.8-25.1-16.7v-2.9h17.5c0.7,7.3,5.2,7.9,11,7.9h26c3.3,0,11,0,11-7.7c0-8.3-5.5-8.3-31.7-8.3
			c-16.4,0-24.4-1-28.7-3.7c-3.7-2.3-4.9-6-4.9-15.2c0-5.9,0.3-11,3.4-14.5c3.5-3.9,10.9-5.8,22.6-5.8H332.5 M1073.8,82l-35.1,65.4
			h-23.9L979.6,82h18.9l26.5,50.4l1.7,3.2l1.7-3.2l26.5-50.4H1073.8 M972.9,82v13.4H945h-1.9v1.9v50.1h-17.4V97.3v-1.9h-1.9h-27.9
			V82H972.9 M871.9,82v65.4h-17.4V82H871.9 M797.6,82v49.5v1.9h1.9h45.8v14h-65.1V82H797.6 M736.4,82l35.9,65.4h-19.2l-6.1-11.2
			l-0.5-1h-1.1h-43h-1.2l-0.5,1l-5.7,11.2h-19.3L711.1,82H736.4 M707.2,124h3.1h27.1h3.2l-1.5-2.8l-13.8-26l-1.7-3.2l-1.7,3.3
			l-13.4,26L707.2,124 M667.8,82v65.4h-30l-39.3-49.5l-0.6-0.7H597h-0.2h-1.9V99v48.4h-17.4V82h30l39.3,49.5l0.6,0.7h0.9h0.2h1.9
			v-1.9V82H667.8 M564.1,82v65.4h-30l-39.3-49.5l-0.6-0.7h-0.9h-0.2h-1.9V99v48.4h-17.4V82h30l39.3,49.5l0.6,0.7h0.9h0.2h1.9v-1.9
			V82H564.1 M245.8,82c13.4,0,18.8,5.4,18.8,18.6v4.4c0,8.4-1.6,13.1-10.4,15.1l-1.5,0.3v1.5v0.2v1.6l1.6,0.3
			c5.1,0.9,10,2.7,10,13.4v9.8H247v-6.4c0-8.3-3-11.6-10.6-11.6H203h-1.9v1.9v16.1h-17.4V82H245.8 M201.1,116h1.9h35.1
			c6.6,0,9.7-3,9.7-9.2v-2.3c0-8.5-5.8-9.2-11.7-9.2H203h-1.9v1.9v16.8V116 M170.6,82v12.2h-52.6h-1.9v1.9v11.1v1.9h1.9h49.7v10.5
			h-49.7h-1.9v1.9v11.1v1.9h1.9h53.8v12.8H98.8V82H170.6 M65.1,82c8.9,0,15,2,18.6,6.1c3.1,3.5,4.5,8.4,4.5,15.7v5.9
			c0,6.1-1.7,20.3-17.6,20.3H28.5h-1.9v1.9v15.5H9.2V82H65.1 M26.6,116.6h1.9h33c5.5,0,8.8-3.5,8.8-9.3v-2.7c0-6.2-3.6-9.3-10.6-9.3
			H28.5h-1.9v1.9v17.4V116.6 M905.1,131.7v15.7h-16.2v-15.7H905.1 M477.1,1h-29.6c-26.8,0-27.9,9.7-27.9,22.2
			c0,17,3.6,20.8,35.5,20.8c27.3,0,29.8,0.4,29.8,6.4c0,5.4-4.6,5.8-9.1,5.8h-26c-6.1,0-9.1-0.6-9.2-7.9h-21.2V53
			c0,15,7.7,18.6,27,18.6h25.7c28.2,0,34.5-3.1,34.5-21.7c0-19.7-7.3-22.1-38.6-22.1c-0.7,0-1.3,0-2,0c-2,0-3.9,0-5.6,0
			c-17.5,0-19.7-0.7-19.7-6.1c0-3.7,3.2-5.4,8-5.4h23.5c9.7,0,10.3,2.1,10.3,7.2h21.2v-2.2C503.9,9.3,501.6,1,477.1,1L477.1,1z
			 M837.8,1.7h-75.7v69.2h76.9V54.3h-55.7V43.2H835V28.9h-51.6V17.8h54.5V1.7L837.8,1.7z M719.7,1.7h-64v69.2h64
			c21.2,0,28.7-7,28.7-24.1v-21C748.4,8.7,740.9,1.7,719.7,1.7L719.7,1.7z M676.9,53.1V18.9h37.7c9.1,0,12.6,2.6,12.6,12.4v9.4
			c0,9.8-3.5,12.4-12.6,12.4H676.9L676.9,53.1z M596.5,1.7h-80.8v17.2h29.8v52h21.2v-52h29.8V1.7L596.5,1.7z M405.7,1.7H330v69.2
			H407V54.3h-55.7V43.2h51.6V28.9h-51.6V17.8h54.5V1.7L405.7,1.7z M319.8,1.7H239v17.2h29.8v52H290v-52h29.8V1.7L319.8,1.7z
			 M433.4,79.3h-34c-19.1,0-30.5,4.8-30.5,25V125c0,20.3,11.4,25,30.5,25h34c19.1,0,30.5-4.8,30.5-25v-20.6
			C463.9,84.1,452.5,79.3,433.4,79.3L433.4,79.3z M402.8,132.3c-10,0-12.1-3.8-12.1-13.9v-7.5c0-8.6,2.1-13.9,13.3-13.9h24.8
			c11.2,0,13.3,5.3,13.3,13.9v7.5c0,10-2.1,13.9-12.1,13.9H402.8L402.8,132.3z M332.5,79.3h-29.6c-26.8,0-27.9,9.7-27.9,22.2
			c0,17,3.6,20.8,35.5,20.8c27.3,0,29.8,0.4,29.8,6.4c0,5.4-4.6,5.8-9.1,5.8h-26c-6.1,0-9.1-0.6-9.2-7.9h-21.2v4.8
			c0,15,7.7,18.6,27,18.6h25.7c28.2,0,34.5-3.1,34.5-21.7c0-19.7-7.3-22.1-38.6-22.1c-0.7,0-1.3,0-2,0c-2,0-3.9,0-5.6,0
			c-17.5,0-19.7-0.7-19.7-6.1c0-3.7,3.2-5.4,8-5.4h23.5c9.7,0,10.3,2.1,10.3,7.2h21.2v-2.2C359.2,87.6,356.9,79.3,332.5,79.3
			L332.5,79.3z M1077,80.1h-23.2l-27,51.4l-27-51.4h-23.2l37.2,69.2h26.2L1077,80.1L1077,80.1z M974.8,80.1H894v17.2h29.8v52H945
			v-52h29.8V80.1L974.8,80.1z M873.8,80.1h-21.2v69.2h21.2V80.1L873.8,80.1z M799.5,80.1h-21.2v69.2h68.9v-17.8h-47.7V80.1
			L799.5,80.1z M737.5,80.1h-27.6l-37.5,69.2h23.7l6.2-12.2h43l6.6,12.2h23.6L737.5,80.1L737.5,80.1z M710.3,122.1l13.4-26l13.8,26
			H710.3L710.3,122.1z M669.7,80.1h-21.2v50.3h-0.2l-39.8-50.3h-32.9v69.2h21.2V99h0.2l39.8,50.3h32.9V80.1L669.7,80.1z M566,80.1
			h-21.2v50.3h-0.2l-39.8-50.3h-32.9v69.2h21.2V99h0.2l39.8,50.3H566V80.1L566,80.1z M245.8,80.1h-64v69.2H203v-18h33.4
			c6.6,0,8.7,2.4,8.7,9.7v8.3h21.2v-11.8c0-11.4-5.3-14.1-11.6-15.3V122c9.7-2.3,11.8-7.7,11.8-17v-4.4
			C266.6,88.2,262.2,80.1,245.8,80.1L245.8,80.1z M203,114.1V97.3h33.2c6.7,0,9.7,1.1,9.7,7.3v2.3c0,5-2.1,7.3-7.8,7.3H203
			L203,114.1z M172.5,80.1H96.8v69.2h76.9v-16.6h-55.7v-11.1h51.6v-14.3h-51.6V96.1h54.5V80.1L172.5,80.1z M65.1,80.1H7.3v69.2h21.2
			v-17.4h42.1c15,0,19.5-11.8,19.5-22.2v-5.9C90.2,90.2,85.3,80.1,65.1,80.1L65.1,80.1z M28.5,114.7V97.3h31.2
			c6.4,0,8.7,2.7,8.7,7.4v2.7c0,4.1-1.9,7.4-6.9,7.4H28.5L28.5,114.7z M907,129.8h-20.1v19.5H907V129.8L907,129.8z"
                    />
                </g>
            </g>
        </svg>
    );
};

TitleIcon.propTypes = propTypes;
TitleIcon.defaultProps = defaultProps;

export default TitleIcon;
