import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import UrbaniaIcon from '../icons/Urbania';
import WithIcon from '../icons/With';
import TouTvIcon from '../icons/TouTv';

import styles from '../../styles/partials/header.module.scss';

const propTypes = {
    className: PropTypes.string,
};

const defaultProps = {
    className: null,
};

const HeaderPartial = ({ className }) => {
    return (
        <div className={classNames([styles.container, { [className]: className !== null }])}>
            <a
                className={styles.link}
                href="https://urbania.ca"
                target="_blank"
                rel="noopener noreferrer"
            >
                <UrbaniaIcon />
            </a>
            <div className={styles.separator}>
                <WithIcon />
            </div>
            <a
                className={styles.link}
                href="https://tou.tv"
                target="_blank"
                rel="noopener noreferrer"
            >
                <TouTvIcon />
            </a>
        </div>
    );
};

HeaderPartial.propTypes = propTypes;
HeaderPartial.defaultProps = defaultProps;

export default HeaderPartial;
