import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router';
import classNames from 'classnames';
import { useFonts } from '@folklore/fonts';

import Grid from '../animations/Grid';
import Noise from '../animations/Noise';

import { usePerspective } from '../contexts/SiteContext';

import styles from '../../styles/layouts/main.module.scss';

const propTypes = {
    children: PropTypes.node.isRequired,
    isPrerender: PropTypes.bool,
    fonts: PropTypes.shape({
        google: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)),
        custom: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)),
    }),
};

const defaultProps = {
    isPrerender: false,
    fonts: {
        google: {
            families: ['Open Sans:400,600,700'],
        },
        custom: {
            families: ['Garage Gothic', 'Apercu'],
        },
    },
};

const MainLayout = ({ fonts, children, isPrerender }) => {
    const { loaded: fontsLoaded } = useFonts(fonts);
    const perspective = usePerspective();
    const { pathname } = useLocation();

    const innerStyle = {
        opacity: fontsLoaded || isPrerender ? 1 : 0,
    };

    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });
    const { width: windowWidth, height: windowHeight } = windowSize || {};

    useEffect(() => {
        const onResize = () => {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        };
        window.addEventListener('resize', onResize);
        return () => {
            window.removeEventListener('resize', onResize);
        };
    }, [setWindowSize]);

    return (
        <div className={classNames([styles.container, { [styles.end]: pathname === '/fin' }])}>
            <Noise active />
            <div className={styles.perspectiveContainer}>
                <div
                    className={classNames([
                        styles.perspective,
                        { [styles.withPerspective]: perspective },
                    ])}
                >
                    <Grid
                        className={styles.grid}
                        animated={perspective}
                        width={windowWidth}
                        height={windowHeight}
                    />
                </div>
            </div>
            <div className={styles.inner} style={innerStyle}>
                {children}
            </div>
        </div>
    );
};

MainLayout.propTypes = propTypes;
MainLayout.defaultProps = defaultProps;

export default MainLayout;
