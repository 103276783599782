import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from '../../styles/icons/default.module.scss';

const propTypes = {
    className: PropTypes.string,
};

const defaultProps = {
    className: null,
};

const TouTvIcon = ({ className }) => {
    return (
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            width="153.7px"
            height="33px"
            viewBox="0 0 153.7 33"
            className={classNames([styles.container, { [className]: className !== null }])}
            xmlSpace="preserve"
        >
            <g>
                <path
                    fill="#B6DEE5"
                    d="M104.1,33H147c3.7,0,6.7-3,6.7-6.7V6.7c0-3.7-3.3-6.7-7-6.7h-42.6V33z"
                />
                <path fill="#00A6B4" d="M104.1,0" />
                <path fill="#00A5AD" d="M104.1,0H6.7C3,0,0,3,0,6.7v19.7C0,30,3,33,6.7,33h97.4" />
                <g>
                    <g>
                        <path
                            fill="#FFFFFF"
                            d="M9,12.7c0-0.5,0.4-0.9,0.9-0.9c0.5,0,0.9,0.4,0.9,0.9v7.6c0,0.5-0.4,0.9-0.9,0.9c-0.5,0-0.9-0.4-0.9-0.9
				V12.7z"
                        />
                        <path
                            fill="#FFFFFF"
                            d="M21.4,12.7c0-0.5,0.4-0.9,0.9-0.9c0.5,0,0.9,0.4,0.9,0.9v7.6c0,0.5-0.4,0.9-0.9,0.9c-0.5,0-0.9-0.4-0.9-0.9
				V12.7z"
                        />
                        <path
                            fill="#FFFFFF"
                            d="M18.3,18.4c0.2-0.3,0.4-0.5,0.8-0.5c0.4,0,0.9,0.4,0.9,0.9c0,0.2,0,0.3-0.1,0.5c-0.6,1.3-2,2.1-3.5,2.1
				c-2.3,0-3.8-1.7-4-3.8c-0.1-0.7-0.1-1.4,0-2.1c0.2-2.1,1.7-3.8,4-3.8c1.5,0,2.9,0.8,3.5,2.1c0.1,0.2,0.1,0.3,0.1,0.5
				c0,0.5-0.5,0.9-0.9,0.9c-0.4,0-0.6-0.3-0.8-0.5c-0.4-0.7-1.1-1.2-1.9-1.2c-1.3,0-2.1,1-2.2,2.3c0,0.6,0,1.1,0,1.7
				c0.1,1.3,0.9,2.2,2.2,2.2C17.2,19.6,17.9,19.2,18.3,18.4"
                        />
                    </g>
                    <g>
                        <path
                            fill="#FFFFFF"
                            d="M71.9,15.6c-0.2-2.1-1.9-3.8-4-3.8c-2.1,0-3.8,1.6-4,3.8c-0.1,0.7-0.1,1.3,0,2c0.2,2.2,1.8,3.8,4,3.8
				c2.1,0,3.8-1.6,4-3.8C72,16.9,72,16.3,71.9,15.6 M67.9,20.2c-1.5,0-2.6-1.1-2.8-2.7c-0.1-0.7-0.1-1.2,0-1.9
				c0.1-1.6,1.3-2.7,2.8-2.7c1.5,0,2.7,1.1,2.8,2.7c0.1,0.7,0.1,1.2,0,1.9C70.6,19.1,69.4,20.2,67.9,20.2"
                        />
                        <path
                            fill="#FFFFFF"
                            d="M99.3,11.8c-0.1,0-0.1,0-0.2,0c-0.3,0-0.5,0.2-0.6,0.4l-2.9,7.9c0,0,0,0.1-0.1,0.1c-0.1,0-0.1,0-0.1-0.1
				l-2.9-7.9c-0.1-0.2-0.3-0.4-0.6-0.4c-0.1,0-0.1,0-0.2,0c-0.1,0.1-0.3,0.2-0.3,0.3c-0.1,0.1-0.1,0.3,0,0.5l2.9,7.7
				c0.3,0.7,0.7,1,1.2,1c0.6,0,1-0.3,1.2-1l2.9-7.7C99.7,12.3,99.6,11.9,99.3,11.8"
                        />
                        <path
                            fill="#FFFFFF"
                            d="M80.4,11.8c-0.3,0-0.6,0.3-0.6,0.6v5c0,1.7-1.1,2.8-2.6,2.8c-1.6,0-2.6-1.1-2.6-2.8v-5
				c0-0.3-0.3-0.6-0.6-0.6c-0.3,0-0.6,0.3-0.6,0.6v5c0,2.3,1.6,4,3.8,4c2.2,0,3.8-1.7,3.8-4v-5C81,12.1,80.7,11.8,80.4,11.8"
                        />
                        <path
                            fill="#FFFFFF"
                            d="M63,11.8h-5.8c-0.3,0-0.6,0.3-0.6,0.6c0,0.3,0.3,0.6,0.6,0.6h2.3v7.7c0,0.3,0.3,0.6,0.6,0.6
				c0.3,0,0.6-0.3,0.6-0.6V13H63c0.3,0,0.6-0.3,0.6-0.6C63.6,12.1,63.3,11.8,63,11.8"
                        />
                        <path
                            fill="#FFFFFF"
                            d="M89.9,11.8h-5.8c-0.3,0-0.6,0.3-0.6,0.6c0,0.3,0.3,0.6,0.6,0.6h2.3v7.7c0,0.3,0.3,0.6,0.6,0.6
				c0.3,0,0.6-0.3,0.6-0.6V13h2.3c0.3,0,0.6-0.3,0.6-0.6C90.5,12.1,90.2,11.8,89.9,11.8"
                        />
                        <path
                            fill="#FFFFFF"
                            d="M82.9,19.6c-0.4,0-0.8,0.4-0.8,0.8c0,0.5,0.4,0.8,0.8,0.8c0.5,0,0.8-0.4,0.8-0.8
				C83.7,19.9,83.4,19.6,82.9,19.6"
                        />
                    </g>
                    <g>
                        <path
                            fill="#FFFFFF"
                            d="M40.6,20.6c2.3,0,4.1-1.8,4.1-4.1s-1.8-4.1-4.1-4.1c-2.3,0-4.1,1.8-4.1,4.1S38.3,20.6,40.6,20.6z"
                        />
                        <g>
                            <path
                                fill="#FFFFFF"
                                d="M31.7,16.5c0,2.3,1.8,4.1,4.1,4.1v-8.2C33.6,12.4,31.7,14.2,31.7,16.5z"
                            />
                            <path
                                fill="#FFFFFF"
                                d="M45.3,12.4L45.3,12.4v8.2l0,0c2.3,0,4.1-1.8,4.1-4.1S47.6,12.4,45.3,12.4z"
                            />
                            <path
                                fill="#FFFFFF"
                                d="M40.6,25.3c2.3,0,4.1-1.8,4.1-4.1h-8.2C36.5,23.5,38.3,25.3,40.6,25.3z"
                            />
                            <path
                                fill="#FFFFFF"
                                d="M36.5,11.8L36.5,11.8l8.2,0v0c0-2.3-1.8-4.1-4.1-4.1C38.3,7.7,36.5,9.5,36.5,11.8z"
                            />
                        </g>
                        <g>
                            <path
                                fill="#FFFFFF"
                                d="M31.9,11.8h3.9V7.9C33.7,7.9,31.9,9.6,31.9,11.8z"
                            />
                            <path
                                fill="#FFFFFF"
                                d="M45.3,7.9L45.3,7.9v3.9h3.9C49.2,9.6,47.5,7.9,45.3,7.9z"
                            />
                            <path
                                fill="#FFFFFF"
                                d="M31.9,21.2h3.9v3.9C33.7,25.1,31.9,23.4,31.9,21.2z"
                            />
                            <path
                                fill="#FFFFFF"
                                d="M45.3,25.1L45.3,25.1v-3.9h3.9C49.2,23.4,47.5,25.1,45.3,25.1z"
                            />
                        </g>
                        <g>
                            <g>
                                <path
                                    fill="#FFFFFF"
                                    d="M50.1,20V13c1.2,0.7,2,2,2,3.5C52.1,18,51.3,19.3,50.1,20z"
                                />
                                <path
                                    fill="#FFFFFF"
                                    d="M31.1,13V20c-1.2-0.7-2-2-2-3.5C29.1,15,29.9,13.7,31.1,13z"
                                />
                            </g>
                            <g>
                                <path
                                    fill="#FFFFFF"
                                    d="M44.1,7h-7.1c0.7-1.2,2-2,3.5-2C42.1,5,43.4,5.8,44.1,7z"
                                />
                                <path
                                    fill="#FFFFFF"
                                    d="M37.1,26h7.1c-0.7,1.2-2,2-3.5,2C39.1,28,37.8,27.2,37.1,26z"
                                />
                            </g>
                        </g>
                    </g>
                </g>
                <g>
                    <g>
                        <path
                            fill="#009EA6"
                            d="M114.7,11.9c0.4,0,0.7,0.3,0.7,0.7c0,0.4-0.3,0.7-0.7,0.7h-4v2.5h3.9c0.4,0,0.7,0.3,0.7,0.7
				c0,0.3-0.3,0.7-0.7,0.7h-3.9v2.6h4.1c0.4,0,0.7,0.3,0.7,0.7c0,0.4-0.3,0.7-0.7,0.7h-4.4c-0.7,0-1.1-0.5-1.1-1.1V13
				c0-0.6,0.4-1.1,1.1-1.1H114.7z"
                        />
                        <path
                            fill="#009EA6"
                            d="M123.9,12.6c0,0.2,0,0.3-0.2,0.5l-2.8,3.5l2.7,3.4c0.1,0.2,0.2,0.3,0.2,0.5c0,0.4-0.3,0.7-0.7,0.7
				c-0.2,0-0.5-0.1-0.6-0.3l-2.5-3.2l-2.5,3.2c-0.1,0.2-0.4,0.3-0.6,0.3c-0.4,0-0.7-0.3-0.7-0.7c0-0.2,0.1-0.3,0.2-0.5l2.7-3.4
				l-2.7-3.5c-0.2-0.3-0.2-0.8,0.1-1c0.3-0.2,0.8-0.2,1,0.1l2.5,3.2l2.5-3.2c0.1-0.2,0.4-0.3,0.6-0.3
				C123.6,11.9,123.9,12.2,123.9,12.6z"
                        />
                        <path
                            fill="#009EA6"
                            d="M127.4,13.3H125c-0.4,0-0.7-0.3-0.7-0.7c0-0.4,0.3-0.7,0.7-0.7h6.1c0.4,0,0.7,0.3,0.7,0.7
				c0,0.4-0.3,0.7-0.7,0.7h-2.4v7.1c0,0.4-0.3,0.7-0.7,0.7c-0.4,0-0.7-0.3-0.7-0.7V13.3z"
                        />
                        <path
                            fill="#009EA6"
                            d="M134.1,17.7v2.7c0,0.4-0.3,0.7-0.7,0.7c-0.4,0-0.7-0.3-0.7-0.7v-7.5c0-0.6,0.4-1,1-1h2.7c1.7,0,3,1,3,2.9
				c0,1.6-0.9,2.3-2,2.6l2.1,2.6c0.2,0.3,0.2,0.7,0,1c-0.3,0.3-0.7,0.2-1,0l-2.3-2.8c-0.2-0.3-0.4-0.4-0.7-0.4H134.1z M136.3,16.4
				c1.3,0,1.8-0.6,1.8-1.5c0-1-0.5-1.5-1.7-1.5h-2.2v3.1H136.3z"
                        />
                        <path
                            fill="#009EA6"
                            d="M147.9,21.1c-0.3,0-0.5-0.2-0.7-0.5l-0.9-2.4h-3.9l-0.9,2.5c-0.1,0.3-0.4,0.4-0.7,0.4
				c-0.4,0-0.7-0.4-0.7-0.7c0-0.1,0-0.2,0-0.3l2.6-7.3c0.3-0.8,0.8-1.1,1.5-1.1c0.7,0,1.2,0.4,1.5,1.1l2.6,7.3c0,0.1,0,0.2,0,0.2
				C148.6,20.7,148.3,21.1,147.9,21.1z M142.9,16.9h3l-1.2-3.5c0-0.1-0.1-0.2-0.3-0.2c-0.1,0-0.2,0.1-0.3,0.2L142.9,16.9z"
                        />
                    </g>
                </g>
            </g>
        </svg>
    );
};

TouTvIcon.propTypes = propTypes;
TouTvIcon.defaultProps = defaultProps;

export default TouTvIcon;
