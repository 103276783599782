import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from '../../styles/partials/head-silhouette.module.scss';

const propTypes = {
    head: PropTypes.string,
    symbol: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    head: null,
    symbol: null,
    className: null,
};

const HeadSilhouette = ({ head, symbol, className }) => {
    return (
        <div className={classNames([styles.container, { [className]: className !== null }])}>
            <div className={styles.inner}>
                <div className={styles.head} style={{ backgroundImage: `url(${head})` }}>
                    <div className={styles.symbolContainer}>
                        <div
                            className={styles.symbol}
                            style={{ backgroundImage: `url(${symbol})` }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

HeadSilhouette.propTypes = propTypes;
HeadSilhouette.defaultProps = defaultProps;

export default HeadSilhouette;
