/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable react/no-danger */
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { useSetResult } from '../contexts/ResultsContext';

import HtmlPartial from './Html';

import { sendEvent } from '../../lib/analytics';
import * as AppPropTypes from '../../lib/PropTypes';

import styles from '../../styles/partials/answer.module.scss';

const propTypes = {
    index: PropTypes.number,
    question: PropTypes.string,
    answer: AppPropTypes.answer,
    symbol: PropTypes.string,
    symbolStyle: PropTypes.object,
    className: PropTypes.string,
};

const defaultProps = {
    index: 0,
    question: null,
    answer: null,
    symbol: null,
    symbolStyle: null,
    className: null,
};

const AnswerPartial = ({ index, question, answer, symbol, symbolStyle, className }) => {
    const setResult = useSetResult();
    const { show, body, image, imageStyle = null } = answer || {};

    const onClick = useCallback(() => {
        sendEvent('click_answer', 'interactions', `${question}-${index}-${show.slug}`);
        setResult(question, show);
    }, [question, show, index, setResult]);

    return (
        <button
            className={classNames([styles.container, { [className]: className !== null }])}
            type="button"
            onClick={onClick}
        >
            <div className={styles.icon}>
                <div
                    className={styles.symbol}
                    style={{ ...symbolStyle, backgroundImage: `url('${symbol}')` }}
                />
                <div
                    className={styles.image}
                    style={{ ...imageStyle, backgroundImage: `url('${image}')` }}
                />
            </div>
            <div className={styles.content}>
                <HtmlPartial className={styles.text} body={body} />
            </div>
        </button>
    );
};

AnswerPartial.propTypes = propTypes;
AnswerPartial.defaultProps = defaultProps;

export default AnswerPartial;
