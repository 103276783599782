import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from '../../styles/partials/beam.module.scss';

const propTypes = {
    className: PropTypes.string,
};

const defaultProps = {
    className: null,
};

const Beam = ({ className }) => {
    return (
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            width="1200px"
            height="300px"
            viewBox="0 0 1200 300"
            className={classNames([styles.container, { [className]: className !== null }])}
            xmlSpace="preserve"
            preserveAspectRatio="none"
        >
            <linearGradient
                id="gradient"
                gradientUnits="userSpaceOnUse"
                x1="600"
                y1="300"
                x2="600"
                y2="0"
            >
                <stop offset="0" stopColor="#FF33CC" stopOpacity="0" />
                <stop offset="0.4859" stopColor="#FF33CC" />
                <stop offset="1" stopColor="#FF33CC" stopOpacity="0" />
            </linearGradient>
            <rect fill="url(#gradient)" width="1200" height="300" />
        </svg>
    );
};

Beam.propTypes = propTypes;
Beam.defaultProps = defaultProps;

export default Beam;
