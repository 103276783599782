import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';

const PagesContext = React.createContext(null);

export const usePagesCtx = () => useContext(PagesContext);

export const usePage = (page) => {
    const { pages } = usePagesCtx();
    return pages[page] || {};
};

export const useGamePages = () => {
    const { pages } = usePagesCtx();
    const gamePages = useMemo(
        () =>
            Object.keys(pages)
                .map((name) =>
                    name !== 'home' && name !== 'end' ? { ...pages[name], slug: name } : null,
                )
                .filter((p) => p !== null),
        [pages],
    );
    return gamePages;
};

const propTypes = {
    children: PropTypes.node.isRequired,
    pages: PropTypes.objectOf(PropTypes.object),
};

const defaultProps = {
    pages: {},
};

export const PagesProvider = ({ children, pages }) => (
    <PagesContext.Provider value={{ pages }}>{children}</PagesContext.Provider>
);

PagesProvider.propTypes = propTypes;
PagesProvider.defaultProps = defaultProps;

export default PagesContext;
