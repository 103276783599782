import heartBeigeSymbol from '../img/symbols/1-heart-beige.svg';
import parasolBeigeSymbol from '../img/symbols/2-parasol-beige.svg';
import burgerBeigeSymbol from '../img/symbols/3-burger-beige.svg';
import moneyBeigeSymbol from '../img/symbols/4-money-beige.svg';
import spiralBeigeSymbol from '../img/symbols/5-spiral-beige.svg';
import arrowBeigeSymbol from '../img/symbols/6-arrow-beige.svg';
import trashBeigeSymbol from '../img/symbols/7-trash-beige.svg';

import heartSymbol from '../img/symbols/1-heart.svg';
import parasolSymbol from '../img/symbols/2-parasol.svg';
import burgerSymbol from '../img/symbols/3-burger.svg';
import moneySymbol from '../img/symbols/4-money.svg';
import spiralSymbol from '../img/symbols/5-spiral.svg';
import arrowSymbol from '../img/symbols/6-arrow.svg';
import trashSymbol from '../img/symbols/7-trash.svg';

import head1 from '../img/heads/1.svg';
import head2 from '../img/heads/2.svg';
import head3 from '../img/heads/3.svg';
import head4 from '../img/heads/4.svg';
import head5 from '../img/heads/5.svg';
import head6 from '../img/heads/6.svg';
import head7 from '../img/heads/7.svg';

import beerAnswer from '../img/answers/1-beer.png';
import fishAnswer from '../img/answers/1-fish.png';
import fishermanAnswer from '../img/answers/1-fisherman.png';

import flagAnswer from '../img/answers/2-flag.png';
import hatAnswer from '../img/answers/2-hat.png';
import planeAnswer from '../img/answers/2-plane.png';

import basketAnswer from '../img/answers/3-basket.png';
import stairsAnswer from '../img/answers/3-stairs.png';
import wistleAnswer from '../img/answers/3-wistle.png';

import deloreanAnswer from '../img/answers/4-delorean.png';
import houseAnswer from '../img/answers/4-house.png';
import manonAnswer from '../img/answers/4-manon.png';

import glassesAnswer from '../img/answers/5-glasses.png';
import headAnswer from '../img/answers/5-head.png';
import karateAnswer from '../img/answers/5-karate.png';

import donutAnswer from '../img/answers/6-donut.png';
import hockeyAnswer from '../img/answers/6-hockey.png';
import mixerAnswer from '../img/answers/6-mixer.png';

import copAnswer from '../img/answers/7-cop.png';
import handAnswer from '../img/answers/7-hand.png';
import heartAnswer from '../img/answers/7-heart.png';

import alaska from '../img/shows/compressed/alaska.jpg';
import appendices from '../img/shows/compressed/appendices.jpg';
import blue from '../img/shows/compressed/blue.jpg';
import faits from '../img/shows/compressed/faits.jpg';
import boys from '../img/shows/compressed/boys.jpg';
import demain from '../img/shows/compressed/demain.jpg';
import district from '../img/shows/compressed/district.jpg';
import dixneufdeux from '../img/shows/compressed/dixneufdeux.jpg';
import downton from '../img/shows/compressed/downton.jpg';
import etape from '../img/shows/compressed/etape.jpg';
import famille from '../img/shows/compressed/famille.jpg';
import galere from '../img/shows/compressed/galere.jpg';
import invincibles from '../img/shows/invincibles.jpg';
import jane from '../img/shows/compressed/jane.jpg';
import aime from '../img/shows/compressed/love.jpg';
import maison from '../img/shows/compressed/maison.jpg';
import master from '../img/shows/compressed/master.jpg';
import mecs from '../img/shows/compressed/mecs.jpg';
import million from '../img/shows/compressed/million.jpg';
import minuit from '../img/shows/compressed/minuit.jpg';
import normal from '../img/shows/compressed/normal.jpg';
import outlander from '../img/shows/compressed/outlander.jpg';
import pays from '../img/shows/compressed/pays.jpg';
import plan from '../img/shows/compressed/plan.jpg';
import quatrieme from '../img/shows/compressed/quatrieme.jpg';
import scott from '../img/shows/compressed/scott.jpg';
import sebastien from '../img/shows/compressed/sebastien.jpg';
import serie from '../img/shows/compressed/serie.jpg';
import shrill from '../img/shows/compressed/shrill.jpg';
import simone from '../img/shows/compressed/simone.jpg';
import trop from '../img/shows/compressed/trop.jpg';

export default {
    locale: 'fr',
    messages: {
        fr: {
            'buttons.start.big': 'Trouve ta personnalité Télé',
            'buttons.start.small': '( en 7 petites questions )',
            'buttons.start.step2': 'Just watch me',
            'buttons.continue': 'Continuer',
            'buttons.show.see_episode': 'Voir l’émission',
            'ending.restart': 'Recommencer',
            'ending.thankyou': 'Merci d’avoir participé à notre test de personnalité!',
            'ending.footer':
                'Vous les avez déjà vues? Pas de problème, vous trouverez de nombreux autres titres disponibles sur ICI TOU.TV EXTRA ! Abonnez-vous dès maintenant pour visionner ce qui vous fait envie, sans pauses publicitaires. Et profitez-en bien, car le premier mois est gratuit!',
        },
    },
    routes: {
        home: '/',
        end: '/fin',
        question: '/:slug',
    },
    pages: {
        home: {
            title: 'Quiz de personnalité',
            body:
                '<p>Étant donné ce long confinement qui nous tient autant en haleine qu’une finale de <em>District 31</em>, nous avons eu envie de vous distraire avec un nouvel algorithme révolutionnaire capable de zapper plus vite que votre beau-père. Ainsi, URBANIA est fière de vous présenter un outil capable de déterminer avec précision (enfin, la plupart du temps, parfois, mais pas toujours) ce que vous devriez regarder lors de votre prochaine séance de procrastination en mode télétravail.</p><p>N’oubliez pas d’ajouter <em>« couch potato »</em> à votre profil LinkedIn après avoir fait le test! ;-)</p>',
        },
        amour: {
            index: 1,
            title: 'Lors d’une peine d’amour, vous êtes du genre à :',
            symbol: heartSymbol,
            symbolBeige: heartBeigeSymbol,
            head: head1,
            answers: [
                {
                    show: 'sebastien',
                    image: fishAnswer,
                    body:
                        'Acheter un poisson rouge pour combattre la solitude et assister, impuissant, à son décès une semaine plus tard parce que vous lui avez donné trop de nourriture.',
                },
                {
                    show: 'simone',
                    image: fishermanAnswer,
                    imageStyle: {
                        width: '140%',
                        height: '140%',
                    },
                    body:
                        'Envoyer le même GIF à tous vos <em>matchs</em> sur les applications de rencontre en espérant que quelqu’un morde à l’hameçon.',
                },
                {
                    show: 'invincibles',
                    image: beerAnswer,
                    body:
                        'Noyer votre chagrin dans le luxe en payant la traite à votre gang sans compter les dépenses.',
                },
            ],
        },
        vacances: {
            index: 2,
            title: 'C’est le temps des vacances! Que faites-vous pour en profiter ?',
            symbol: parasolSymbol,
            symbolBeige: parasolBeigeSymbol,
            symbolBeigeStyle: {
                top: '-15%',
                left: '-5%',
            },
            head: head2,
            answers: [
                {
                    show: 'etape',
                    image: hatAnswer,
                    body:
                        'Vous appelez votre entrepreneur général et entreprenez des rénovations desquelles vous ne sortirez jamais.',
                },
                {
                    show: 'famille',
                    image: flagAnswer,
                    body:
                        'Vous quittez votre deux et demi d’Hochelag pour vous ressourcer dans un Airbnb luxueux en Angleterre.',
                },
                {
                    show: 'quatrieme',
                    image: planeAnswer,
                    body:
                        'Vous réservez un de ces fameux vols pour nulle part devenus populaires depuis la pandémie.',
                },
            ],
        },
        lunch: {
            index: 3,
            title: 'Votre heure de lunch au secondaire, vous la passiez avec…',
            symbol: burgerSymbol,
            symbolBeige: burgerBeigeSymbol,
            head: head3,
            answers: [
                {
                    show: 'boys',
                    image: basketAnswer,
                    body:
                        'Les sportifs en herbe qui sentent toujours le <em>swing</em> et qui ont des <em>shakes</em> de protéines comme lunch.',
                },
                {
                    show: 'normal',
                    image: stairsAnswer,
                    body: 'Vous-même, assis dans un escalier pour avoir la sainte paix.',
                },
                {
                    show: 'appendices',
                    image: wistleAnswer,
                    body:
                        'Les champions de l’impro, qui depuis leur tendre enfance rêvent de mettre de l’avant leurs talents à Cégeps en spectacle.',
                },
            ],
        },
        impot: {
            index: 4,
            title: 'Vous recevez un gros remboursement d’impôt : qu’en faites-vous?',
            symbol: moneySymbol,
            symbolBeige: moneyBeigeSymbol,
            head: head4,
            answers: [
                {
                    show: 'downton',
                    image: houseAnswer,
                    body:
                        'Vous le mettez de côté afin d’amasser une mise de fonds pour un jour acheter une grosse cabane à Westmount.',
                },
                {
                    show: 'plan',
                    image: deloreanAnswer,
                    body:
                        'Vous investissez votre argent dans une <em>start-up</em> qui développe une technologie de voyage dans le temps.',
                },
                {
                    show: 'maison',
                    image: manonAnswer,
                    body:
                        'Vous faites un don à votre parti politique préféré en espérant recevoir une photo autographiée de votre député favori.',
                },
            ],
        },
        remise: {
            index: 5,
            title: 'Lors de votre dernière remise en question vous avez…',
            symbol: spiralSymbol,
            symbolBeige: spiralBeigeSymbol,
            head: head5,
            answers: [
                {
                    show: 'mecs',
                    image: headAnswer,
                    body:
                        'Appelé le 811 pour savoir comment traiter votre calvitie avant qu’il ne soit trop tard.',
                },
                {
                    show: 'shrill',
                    image: glassesAnswer,
                    body:
                        '« Passé la moppe » dans votre vie en coupant les ponts avec des amis toxiques.',
                },
                {
                    show: 'serie',
                    image: karateAnswer,
                    body:
                        'Décidé de vous inscrire à des cours de karaté afin d’augmenter votre confiance en vous en maîtrisant le nunchaku.',
                },
            ],
        },
        vie: {
            index: 6,
            title: 'Dans une autre vie vous étiez…',
            symbol: arrowSymbol,
            symbolBeige: arrowBeigeSymbol,
            head: head6,
            answers: [
                {
                    show: 'district',
                    image: donutAnswer,
                    body:
                        'Un policier qui prend un malin plaisir à donner des claques sur la gueule aux voyous au lieu d’une contravention.',
                },
                {
                    show: 'minuit',
                    image: mixerAnswer,
                    body:
                        'DJ dans une boîte de nuit où votre musique accompagne autant les fêtards que les batailles sur la piste de danse.',
                },
                {
                    show: 'demain',
                    image: hockeyAnswer,
                    body: 'Un joueur de hockey un peu trop sur le party.',
                },
            ],
        },
        memoire: {
            index: 7,
            title: 'Votre mémoire est au maximum de sa capacité. Quel souvenir supprimez-vous?',
            symbol: trashSymbol,
            symbolBeige: trashBeigeSymbol,
            head: head7,
            answers: [
                {
                    show: 'blue',
                    image: copAnswer,
                    body:
                        'La fois où vous vous êtes fait pincer par un policier pour avoir tenté d’acheter de l’alcool avec de fausses cartes à New York pendant une sortie scolaire.',
                },
                {
                    show: 'alaska',
                    image: heartAnswer,
                    body:
                        'Quand ça vous a pris tout votre courage pour demander à votre <em>crush</em> d’aller au bal de finissants pour découvrir que vos sentiments n’étaient pas réciproques.',
                },
                {
                    show: 'master',
                    image: handAnswer,
                    body:
                        'Lorsque vous étiez tellement cassé que vous avez participé à une étude sur la masturbation pour le cours de sexologie de votre coloc.',
                },
            ],
        },
        end: {
            title: "Merci d'avoir participé au psycho-test",
        },
    },
    shows: {
        sebastien: {
            name: 'Dr Sébastien, vétérinaire',
            url: 'https://ici.tou.tv/dr-sebastien-veterinaire',
            image: sebastien,
            video: 'https://cdn.urbania.ca/videos/tou-tv/sebastien.mp4',
            body:
                'Grâce au Dr. Sébastien, vous oublierez votre ex et apprendrez à prendre soin d’un animal qui vous sera toujours fidèle.',
        },
        simone: {
            name: 'Les Simone',
            url: 'https://ici.tou.tv/les-simone',
            image: simone,
            video: 'https://cdn.urbania.ca/videos/tou-tv/simone.mp4',
            body:
                'Malgré vos piètres techniques de cruise, vous pouvez compter sur Nikki, barmaid dans <em>Les Simone</em>, qui saura vous en apprendre des meilleures, entre 2 shooters.',
        },
        invincibles: {
            name: 'Les Invincibles',
            url: 'https://ici.tou.tv/les-invincibles',
            image: invincibles,
            video: 'https://cdn.urbania.ca/videos/tou-tv/invincibles.mp4',
            body:
                'Votre générosité est comparable à celle de Rémi...en espérant que votre maturité ne l’est pas!',
        },
        etape: {
            name: 'Une étape à la fois',
            url: 'https://ici.tou.tv/une-etape-a-la-fois',
            image: etape,
            video: 'https://cdn.urbania.ca/videos/tou-tv/etape.mp4',
            body:
                'Préparez-vous à passer beaucoup de temps à regarder Marilou...et à avoir envie de changer l’accès à votre garage au moins 3 fois!',
        },
        famille: {
            name: 'La famille royale',
            url: 'https://ici.tou.tv/la-famille-royale',
            image: famille,
            video: null,
            body:
                'La famille royale saura ainsi vous accueillir dans l’opulence...et ira peut-être même jusqu’à vous trahir!',
        },
        quatrieme: {
            name: 'La Quatrième Dimension',
            url: 'https://ici.tou.tv/the-twilight-zone-la-quatrieme-dimension',
            image: quatrieme,
            video: null,
            body:
                'Vous êtes dans l’avion. Du hublot, vous observez un monstre et un moteur en feu. Est-ce un rêve? Non. Vous êtes en direction de la Quatrième Dimension...',
        },
        boys: {
            name: 'Les Boys',
            url: 'https://ici.tou.tv/les-boys',
            image: boys,
            video: null,
            body:
                'Vous vous ennuyez de votre gang de <em>boys</em> du secondaire? La gang de Stan vous souhaite la bienvenue!',
        },
        normal: {
            name: 'Normal People',
            url: 'https://ici.tou.tv/normal-people-des-gens-normaux',
            image: normal,
            video: null,
            body:
                'Préparez-vous à revivre les feelings de solitude du secondaire, mais cette fois-ci accompagné de Marianne, une Irlandaise qui n’a pas la langue dans sa poche.',
        },
        appendices: {
            name: 'Les Appendices : de retour après la pause',
            url: 'https://ici.tou.tv/les-appendices-de-retour-apres-la-pause',
            image: appendices,
            video: 'https://cdn.urbania.ca/videos/tou-tv/appendices.mp4',
            body:
                'Vous avez peut-être abandonné votre rêve de devenir humoriste, mais vous pouvez toujours compter sur les sketchs «&nbsp;absurdes et déjantés&nbsp;» des <em>Appendices</em> pour vous replonger dans votre passé comique.',
        },
        downton: {
            name: 'Downton Abbey',
            url: 'https://ici.tou.tv/downton-abbey',
            image: downton,
            video: null,
            body: 'Vous allez peut-être vouloir troquer Westmount pour Hampshire, en Angleterre.',
        },
        plan: {
            name: 'Plan B',
            url: 'https://ici.tou.tv/plan-b',
            image: plan,
            video: 'https://cdn.urbania.ca/videos/tou-tv/plan.mp4',
            body: 'Mais faites attention de ne pas commettre les mêmes erreurs que Philippe...',
        },
        maison: {
            name: 'La Maison-Bleue',
            url: 'https://ici.tou.tv/la-maison-bleue',
            image: maison,
            video: 'https://cdn.urbania.ca/videos/tou-tv/maison.mp4',
            body:
                'Vous allez peut-être vouloir financer la campagne de Guy Nadon, s’il se lance un jour en politique!',
        },
        mecs: {
            name: 'Les mecs',
            url: 'https://ici.tou.tv/les-mecs',
            image: mecs,
            video: 'https://cdn.urbania.ca/videos/tou-tv/mecs.mp4',
            body:
                'Vous vous réconfortez dans le fait que même si vous êtes chauve, vous ne deviendrez jamais un chauvin comme Christian dans <em>Les mecs</em>!',
        },
        shrill: {
            name: 'Shrill',
            url: 'https://ici.tou.tv/shrill',
            image: shrill,
            video: null,
            body: 'Tout comme Annie, un nouveau monde s’ouvrira à vous.',
        },
        serie: {
            name: 'Série Noire',
            url: 'https://ici.tou.tv/serie-noire',
            image: serie,
            video: null,
            body:
                'Vous réaliserez qu’il vous reste beaucoup de coups de poings sur la gueule à manger avant de devenir aussi agile que Marc Arcand!',
        },
        district: {
            name: 'District 31',
            url: 'https://ici.tou.tv/district-31',
            image: district,
            video: null,
            body:
                'Alors c’est que vous étiez peut-être le confrère ou la consœur de Patrick, Bruno et Poupou dans une autre vie.',
        },
        minuit: {
            name: 'Minuit, le soir',
            url: 'https://ici.tou.tv/minuit-le-soir',
            image: minuit,
            video: 'https://cdn.urbania.ca/videos/tou-tv/minuit.mp4',
            body:
                'Revivez vos années de gloires en tant que DJ du bar Le Manhattan avec <em>Minuit le soir</em>!',
        },
        demain: {
            name: 'Demain des hommes',
            url: 'https://ici.tou.tv/demain-des-hommes',
            image: demain,
            video: null,
            body: 'Vous étiez probablement défenseur pour Les Draveurs de Montferrand.',
        },
        blue: {
            name: 'Blue Bloods',
            url: 'https://ici.tou.tv/blue-bloods',
            image: blue,
            video: null,
            body:
                'À oublier, car le policier n’avait pas une moustache aussi belle que celle de Tom Selleck!',
        },
        alaska: {
            name: 'Qui es-tu Alaska?',
            url: 'https://ici.tou.tv/qui-es-tu-alaska',
            image: alaska,
            video: null,
            body:
                'Vous allez réaliser que ce n’était pas si pire en comparaison aux drames que vivent la bande de Pudge.',
        },
        master: {
            name: 'Masters of Sex',
            url: 'https://ici.tou.tv/masters-of-sex',
            image: master,
            video: null,
            body:
                'Avec le Dr. Masters, vous allez ainsi en apprendre plus sur le sexe sans avoir à en parler à votre coloc!',
        },
        aime: {
            name: "C'est comme ça que je t'aime",
            url: 'https://ici.tou.tv/c-est-comme-ca-que-je-t-aime',
            image: aime,
            video: 'https://cdn.urbania.ca/videos/tou-tv/aime.mp4',
        },
        dixneufdeux: {
            name: '19-2',
            url: 'https://ici.tou.tv/19-2',
            image: dixneufdeux,
            video: null,
        },
        jane: {
            name: "Jane l'immaculée",
            url: 'https://ici.tou.tv/jane-l-immaculee',
            image: jane,
            video: null,
        },
        galere: {
            name: 'La galère',
            url: 'https://ici.tou.tv/la-galere',
            image: galere,
            video: null,
        },
        faits: {
            name: 'Faits divers',
            url: 'https://ici.tou.tv/faits-divers',
            image: faits,
            video: 'https://cdn.urbania.ca/videos/tou-tv/faits.mp4',
        },
        scott: {
            name: 'Les frères Scott',
            url: 'https://ici.tou.tv/les-freres-scott',
            image: scott,
            video: null,
        },
        pays: {
            name: "Les pays d'en haut",
            url: 'https://ici.tou.tv/les-pays-d-en-haut',
            image: pays,
            video: 'https://cdn.urbania.ca/videos/tou-tv/pays.mp4',
        },
        trop: {
            name: 'Trop',
            url: 'https://ici.tou.tv/trop',
            image: trop,
            video: 'https://cdn.urbania.ca/videos/tou-tv/trop.mp4',
        },
        outlander: {
            name: 'Outlander le chardon et le tartan',
            url: 'https://ici.tou.tv/outlander',
            image: outlander,
            video: null,
        },
        million: {
            name: 'Un million de petites choses',
            url: 'https://ici.tou.tv/un-million-de-petites-choses',
            image: million,
            video: null,
        },
    },
    profiles: [
        {
            shows: ['invincibles', 'quatrieme', 'mecs', 'minuit', 'blue', 'boys', 'maison'],
            advice: ['faits', 'million', 'scott'],
            body:
                'Selon nos calculs semi-scientifiques dont Charles Tisseyre serait fier, vous êtes probablement du genre à apprécier une soirée bière et pizz’ entre amis pour vous remonter le moral après une longue journée. Cela dit, malgré votre côté <em>« basic »</em>, vous avez aussi un intérêt pour ce qui sort de l’ordinaire et êtes convaincu que nous ne sommes pas seuls dans l’univers. Ces séries risquent donc de vous plaire :',
        },
        {
            shows: ['etape', 'downton', 'normal', 'shrill', 'district', 'simone', 'master'],
            advice: ['pays', 'galere', 'trop'],
            body:
                'À la suite de l’analyse exécutée à l’obtention de vos résultats, nous sommes certains que votre boîte de courriels regorge d’une multitude d’infolettres qui proposent des recettes ou prodiguent des conseils relationnels. Vous semblez également ne pas être du genre à vous laisser damer le pion par le premier venu. Vous êtes le maître de votre vie, tout comme celui de votre télécommande. Ces séries risquent de vous plaire :',
        },
        {
            shows: ['demain', 'sebastien', 'serie', 'alaska', 'famille', 'plan', 'appendices'],
            advice: ['aime', 'dixneufdeux', 'jane'],
            body:
                'Si on se fie à vos réponses, vous partagez probablement votre téléviseur avec quelques colocs, membres de votre famille et animaux de compagnie. Bref, vous êtes plus du genre à ne pas vous battre pour la télécommande et à laisser les autres choisir. Par contre, vous rêvez du jour ou vous allez pouvoir binger une émission que TOUT le monde aime. Ces séries risquent de vous plaire :',
        },
    ],
};
