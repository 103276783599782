import React, { useState, useCallback, useRef, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { Transition } from 'react-transition-group';

import { useUrlGenerator } from '../contexts/RoutesContext';
import { useResult } from '../contexts/ResultsContext';

import PageMeta from '../partials/PageMeta';
import Show from '../partials/Show';
import HtmlPartial from '../partials/Html';
import PinkButton from '../buttons/Pink';
import Brain from '../animations/Brain';

import { sendEvent } from '../../lib/analytics';
import { enter, exit } from '../../lib/animations';

import styles from '../../styles/pages/end.module.scss';

const propTypes = {};

const EndPage = () => {
    const url = useUrlGenerator();
    const brainRef = useRef();
    const resultRef = useRef();

    const { advice, body } = useResult();
    const [calculating, setCalculating] = useState(true);

    const endCalculation = useCallback(() => {
        setCalculating(false);
    }, [setCalculating]);

    const onClickRestart = useCallback(() => {
        sendEvent('click_restart', 'interactions', 'end');
    }, []);

    useEffect(() => {
        const items = advice && advice.length > 0 ? advice.map((a) => a.slug).join('-') : 'empty';
        sendEvent('ending', 'page', items);
    }, []);

    return (
        <div className={styles.container}>
            <PageMeta />
            <Transition
                nodeRef={brainRef}
                in={calculating}
                appear
                onEnter={(appears) => enter(brainRef.current, appears)}
                onExit={(appears) => exit(brainRef.current, appears)}
                onEntered={endCalculation}
                timeout={{ appear: 2000, enter: 0, exit: 2000 }}
            >
                <div ref={brainRef} className={styles.brain}>
                    <Brain fillColor="transparent" />
                </div>
            </Transition>
            <Transition
                nodeRef={resultRef}
                in={!calculating}
                onEnter={(appears) => enter(resultRef.current, appears)}
                timeout={{ appear: 0, enter: 4000, exit: 0 }}
            >
                <div ref={resultRef} className={styles.inner}>
                    <div className={styles.left}>
                        <h1 className={styles.title}>
                            <FormattedMessage id="ending.thankyou" defaultMessage="Merci" />
                        </h1>
                        <div className={styles.text}>
                            <HtmlPartial body={body} />
                        </div>
                        <p className={styles.textFoot}>
                            <strong>
                                <FormattedMessage id="ending.footer" defaultMessage="Tou.tv" />
                            </strong>
                        </p>
                        <div className={styles.home}>
                            <PinkButton href={url('home')} onClick={onClickRestart}>
                                <FormattedMessage
                                    id="ending.restart"
                                    defaultMessage="Recommencer"
                                />
                            </PinkButton>
                        </div>
                    </div>
                    <div className={styles.right}>
                        {advice.map((show, i) => (
                            <Show
                                className={styles.show}
                                key={`show-${show.slug}-${i + 1}`}
                                show={show}
                            />
                        ))}
                    </div>
                    <div className={styles.homeMobile}>
                        <p className={styles.text}>
                            <strong>
                                <FormattedMessage id="ending.footer" defaultMessage="Tou.tv" />
                            </strong>
                        </p>
                        <PinkButton href={url('home')}>
                            <FormattedMessage id="ending.restart" defaultMessage="Recommencer" />
                        </PinkButton>
                    </div>
                </div>
            </Transition>
        </div>
    );
};

EndPage.propTypes = propTypes;

export default EndPage;
