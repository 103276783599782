import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from '../../styles/icons/default.module.scss';

const propTypes = {
    color: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    color: 'currentColor',
    className: null,
};

const ArrowIcon = ({ color, className }) => {
    return (
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            width="23px"
            height="23px"
            viewBox="0 0 23 23"
            className={classNames([styles.container, { [className]: className !== null }])}
            xmlSpace="preserve"
        >
            <g>
                <line
                    fill="none"
                    stroke={color}
                    strokeWidth="3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    x1="1.5"
                    y1="11.5"
                    x2="21.5"
                    y2="11.5"
                />
                <polyline
                    fill="none"
                    stroke={color}
                    strokeWidth="3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    points="11.5,1.5 21.5,11.5 11.5,21.5"
                />
            </g>
        </svg>
    );
};

ArrowIcon.propTypes = propTypes;
ArrowIcon.defaultProps = defaultProps;

export default ArrowIcon;
