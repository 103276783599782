import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import { usePage } from '../contexts/PagesContext';
import { useResults } from '../contexts/ResultsContext';
import { useUrlGenerator } from '../contexts/RoutesContext';
import { useSetPerspective } from '../contexts/SiteContext';

import PageMeta from '../partials/PageMeta';
import IndicatorsMenu from '../menus/Indicators';
import Answer from '../partials/Answer';
import HtmlPartial from '../partials/Html';
import Show from '../partials/Show';
import HeadSilhouette from '../partials/HeadSilhouette';
import PinkButton from '../buttons/Pink';

import Beam from '../partials/Beam';

import { sendEvent } from '../../lib/analytics';
import * as AppPropTypes from '../../lib/PropTypes';

import styles from '../../styles/pages/question.module.scss';

const propTypes = {
    location: AppPropTypes.location.isRequired,
    beamAnimationDuration: PropTypes.number,
    beamAnimationDelay: PropTypes.number,
};

const defaultProps = {
    beamAnimationDuration: 1500, // ms
    beamAnimationDelay: 500, // ms - @TODO existe seulement pour compenser le délai du transition de page...
};

const QuizPage = ({ location, beamAnimationDuration, beamAnimationDelay }) => {
    const slug = location.pathname.replace(/\\|\//g, '');
    const url = useUrlGenerator();
    const page = usePage(slug);
    const results = useResults(slug);
    const setPerspective = useSetPerspective();
    const { index, title, symbol, symbolBeige, symbolBeigeStyle = null, head, next } = page || {};
    const result = results[slug] ? results[slug] : null;
    const nextUrl = next ? url('question', { slug: next }) : url('end');

    const [hasResult, setHasResult] = useState(result !== null);

    const showResults = result !== null && hasResult;

    useEffect(() => {
        if (result) {
            setPerspective(false);
            setHasResult(true);
        } else {
            setPerspective(true);
            setHasResult(false);
        }
    }, [result, setPerspective]);

    const [beamAnimated, setBeamAnimated] = useState(false);

    useEffect(() => {
        let timeout = null;

        if (!showResults) {
            timeout = setTimeout(setBeamAnimated, beamAnimationDelay + beamAnimationDuration, true);
        } else {
            setBeamAnimated(false);
        }
        return () => {
            if (timeout !== null) {
                clearTimeout(timeout);
            }
        };
    }, [showResults, beamAnimationDelay, beamAnimationDuration, setBeamAnimated]);

    const onClickContinue = useCallback(() => {
        sendEvent('click_continue', 'interactions', `${slug}`);
    });

    const topContent = (
        <>
            <IndicatorsMenu current={index} />
            <h1 className={classNames([styles.title, { [styles.hidden]: hasResult }])}>{title}</h1>
        </>
    );

    return (
        <div
            className={classNames([
                styles.container,
                {
                    [styles.showAnswers]: !showResults,
                    [styles.showResults]: showResults,
                    [styles.beamAnimated]: beamAnimated,
                },
            ])}
        >
            <PageMeta />
            <div
                className={styles.headSilhouetteContainer}
                style={{
                    animationDuration: `${beamAnimationDuration}ms`,
                    animationDelay: `${beamAnimationDelay}ms`,
                }}
            >
                <HeadSilhouette className={styles.headSilhouette} head={head} symbol={symbol} />
            </div>
            <div className={classNames([styles.top, styles.topInitial])}>{topContent}</div>
            {!showResults ? (
                <div
                    className={styles.answers}
                    style={{
                        animationDuration: `${beamAnimationDuration}ms`,
                        animationDelay: `${beamAnimationDelay}ms`,
                    }}
                >
                    <div className={classNames([styles.top, styles.topAfterAnim])}>
                        {topContent}
                    </div>
                    <div className={styles.answersListContainer}>
                        <div className={styles.answersList}>
                            {page.answers.map((answer, i) => (
                                <Answer
                                    className={styles.answer}
                                    key={`answer-${answer.body}`}
                                    index={i}
                                    question={slug}
                                    answer={answer}
                                    symbol={symbolBeige}
                                    symbolStyle={symbolBeigeStyle}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            ) : (
                <div className={styles.resultsContainer}>
                    <div className={styles.results}>
                        {result.body ? (
                            <HtmlPartial
                                className={classNames([styles.text, styles.textTop])}
                                body={result.body}
                            />
                        ) : null}
                        <div className={styles.result}>
                            <Show show={result} />
                        </div>
                        <div className={styles.nextContainer}>
                            {result.body ? (
                                <HtmlPartial
                                    className={classNames([styles.text, styles.textBottom])}
                                    body={result.body}
                                />
                            ) : null}
                            <PinkButton
                                className={styles.next}
                                href={nextUrl}
                                onClick={onClickContinue}
                                withIcon
                            >
                                <FormattedMessage
                                    id="buttons.continue"
                                    defaultMessage="Continuer"
                                    description="Button label"
                                />
                            </PinkButton>
                        </div>
                    </div>
                </div>
            )}
            <div
                className={styles.beamContainer}
                style={{
                    animationDuration: `${beamAnimationDuration}ms`,
                    animationDelay: `${beamAnimationDelay}ms`,
                }}
            >
                <Beam className={styles.beam} />
            </div>
            <div className={styles.flashLayer} />
        </div>
    );
};

QuizPage.propTypes = propTypes;
QuizPage.defaultProps = defaultProps;

export default QuizPage;
