/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import noise from '../../img/noise.gif';

import styles from '../../styles/animations/noise.module.scss';

const propTypes = {
    className: PropTypes.string,
    active: PropTypes.bool,
};

const defaultProps = {
    className: null,
    active: false,
};

const Noise = ({ className, active }) => (
    <div
        className={classNames([styles.container, { [className]: className !== null, [styles.active] : active }])}
        style={{ backgroundImage: `url(${noise})` }}
    />
);

Noise.propTypes = propTypes;
Noise.defaultProps = defaultProps;

export default Noise;
