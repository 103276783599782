import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, useHistory, useLocation } from 'react-router';
import { Transition } from 'react-transition-group';

import { enterPage } from '../lib/animations';
import { useUrlGenerator } from './contexts/RoutesContext';

import MainLayout from './layouts/Main';
import HomePage from './pages/Home';
import QuestionPage from './pages/Question';
import EndPage from './pages/End';
import ErrorPage from './pages/Error';

const propTypes = {
    transitionTime: PropTypes.number,
};

const defaultProps = {
    transitionTime: 0,
};

const App = ({ transitionTime }) => {
    const homeRef = useRef();
    const endRef = useRef();
    const questionRef = useRef();

    const { pathname } = useLocation();
    const history = useHistory();
    const url = useUrlGenerator();

    // Reset request on history change
    useEffect(() => {
        const unlisten = history.listen(() => {});
        return () => {
            unlisten();
        };
    }, [history]);

    return (
        <MainLayout>
            <Switch>
                <Route exact path={url('home')} component={HomePage}>
                    {({ match }) => (
                        <Transition
                            nodeRef={homeRef}
                            in={match != null}
                            appear
                            onEnter={(node, appears) => enterPage(homeRef.current, appears)}
                            timeout={{ enter: transitionTime }}
                            unmountOnExit
                        >
                            <div ref={homeRef} className="page">
                                <HomePage />
                            </div>
                        </Transition>
                    )}
                </Route>
                <Route exact path={url('end')}>
                    {({ match }) => (
                        <Transition
                            nodeRef={endRef}
                            in={match != null}
                            appear
                            onEnter={(node, appears) => enterPage(endRef.current, appears)}
                            timeout={{ enter: transitionTime }}
                            unmountOnExit
                        >
                            <div ref={endRef} className="page">
                                <EndPage />
                            </div>
                        </Transition>
                    )}
                </Route>
                <Route path={url('question', { slug: '*' })}>
                    {({ match }) => (
                        <Transition
                            nodeRef={questionRef}
                            in={match != null}
                            key={pathname}
                            appear
                            onEnter={(node, appears) => enterPage(questionRef.current, appears)}
                            timeout={{ enter: transitionTime }}
                            unmountOnExit
                        >
                            <div ref={questionRef} className="page">
                                <QuestionPage location={{ pathname }} />
                            </div>
                        </Transition>
                    )}
                </Route>
                <Route path="*" component={ErrorPage} />
            </Switch>
        </MainLayout>
    );
};

App.propTypes = propTypes;
App.defaultProps = defaultProps;

export default App;
