import React, { useContext, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import difference from 'lodash/difference';
import sortBy from 'lodash/sortBy';

import * as AppPropTypes from '../../lib/PropTypes';

const ResultsContext = React.createContext(null);

export const useResultsCtx = () => useContext(ResultsContext);

export const useResults = () => {
    const { results } = useResultsCtx();
    return results;
};

export const useSetResult = () => {
    const { setResult } = useResultsCtx();
    return setResult;
};

export const useResult = () => {
    const { results, profiles } = useResultsCtx();
    const slugs = Object.keys(results).map((name) => results[name].slug);

    const profilesWithCount = sortBy(
        profiles.map((profile) => {
            const items = difference(slugs, profile.shows);
            return { ...profile, count: slugs.length - items.length };
        }, {}),
        (p) => p.count,
    ).reverse();

    return profilesWithCount[0];
};

export const useReset = () => {
    const { setResults } = useResultsCtx();
    return useCallback(() => {
        setResults({});
    }, []);
};

const propTypes = {
    children: PropTypes.node.isRequired,
    shows: AppPropTypes.shows,
    profiles: AppPropTypes.profiles,
};

const defaultProps = {
    shows: {},
    profiles: [],
};

export const ResultsProvider = ({ children, shows, profiles }) => {
    const [results, setResults] = useState({});
    const setResult = useCallback(
        (slug, value) => {
            setResults({
                ...results,
                [slug]: value,
            });
        },
        [results, setResults],
    );
    return (
        <ResultsContext.Provider value={{ setResult, setResults, results, shows, profiles }}>
            {children}
        </ResultsContext.Provider>
    );
};

ResultsProvider.propTypes = propTypes;
ResultsProvider.defaultProps = defaultProps;

export default ResultsContext;
