export const sendEvent = (action = 'click', category = '', label = '', value = 1) => {
    const dataLayer =
        typeof window.dataLayer !== 'undefined' && window.dataLayer ? window.dataLayer : null;
    // console.log('event', action, category, label, value); // eslint-disable-line
    if (!dataLayer) return;
    // console.log('push', gtag); // eslint-disable-line

    dataLayer.push({
        event: action,
        event_category: category,
        event_label: label,
        event_value: value,
    });
};

export default {
    sendEvent,
};
