import React, { useState, useEffect, useCallback, useRef } from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { Transition } from 'react-transition-group';

import { useUrlGenerator } from '../contexts/RoutesContext';
import { usePage } from '../contexts/PagesContext';
import { useReset } from '../contexts/ResultsContext';
import { useSetPerspective, usePerspective } from '../contexts/SiteContext';

import PageMeta from '../partials/PageMeta';
import Header from '../partials/Header';
import PinkButton from '../buttons/Pink';
import Brain from '../animations/Brain';
import BrainFilled from '../animations/BrainFilled';
import TitleIcon from '../icons/Title';
import styles from '../../styles/pages/home.module.scss';
import HtmlPartial from '../partials/Html';

import { sendEvent } from '../../lib/analytics';
import { reveal } from '../../lib/animations';

const propTypes = {};

const HomePage = () => {
    const [hasRendered, setHasRendered] = useState(false);
    const headerRef = useRef();
    const buttonRef = useRef();
    const perspective = usePerspective();
    const setPerspective = useSetPerspective();
    const reset = useReset();
    const url = useUrlGenerator();
    const page = usePage('home');

    useEffect(() => {
        reset();
        setHasRendered(true);
    }, [reset, setHasRendered]);

    const [open, setOpen] = useState(false);
    const onClick = useCallback(() => {
        setOpen(true);
        sendEvent('click_start', 'interactions', 'home');
    }, [setOpen]);

    const onClickNext = useCallback(() => {
        sendEvent('click_start_next', 'interactions', 'home');
    }, []);

    const onEntered = useCallback(() => {
        setPerspective(true);
    }, [setPerspective]);

    return (
        <div
            className={classNames([
                styles.container,
                {
                    [styles.open]: open,
                },
            ])}
        >
            <PageMeta />
            <Transition
                in
                nodeRef={headerRef}
                appear
                onEnter={(appears) => reveal(headerRef.current, appears, 4)}
                onEntered={onEntered}
                timeout={4000}
            >
                <div ref={headerRef} className={styles.header}>
                    <Header />
                </div>
            </Transition>
            {open ? (
                <Brain
                    className={classNames([styles.brainMobile])}
                    color="currentColor"
                    strokeWidth={1}
                    fillColor="currentColor"
                />
            ) : null}
            <div className={styles.content}>
                <BrainFilled
                    className={classNames([
                        styles.brainFilled,
                        {
                            [styles.show]: open,
                        },
                    ])}
                    filled={open}
                />
                <Brain
                    className={classNames([
                        styles.brain,
                        {
                            [styles.drawing]: hasRendered && !perspective,
                            [styles.lined]: !open,
                            [styles.done]: !open && perspective,
                            [styles.white]: open,
                        },
                    ])}
                    color={open ? '#FFF' : 'currentColor'}
                    strokeWidth={open ? 3 : 3}
                    fillColor={perspective ? 'transparent' : 'transparent'}
                />
                {open ? (
                    <div className={styles.innerBrain}>
                        <div className={styles.spacer} />
                        <HtmlPartial className={styles.paragraph} body={page.body} />
                        <PinkButton
                            className={styles.pinkButton}
                            href={url('question', { slug: 'amour' })}
                            onClick={onClickNext}
                        >
                            <span>
                                <FormattedMessage
                                    id="buttons.start.step2"
                                    defaultMessage="Ok let's go"
                                    desctription="Button label"
                                />
                            </span>
                        </PinkButton>
                    </div>
                ) : (
                    <Transition
                        in
                        nodeRef={buttonRef}
                        appear
                        onEnter={(appears) => reveal(buttonRef.current, appears, 4)}
                        timeout={4000}
                    >
                        <div ref={buttonRef} className={styles.innerBrain}>
                            <div className={styles.spacer} />
                            <h1 className={styles.title}>
                                <TitleIcon />
                            </h1>
                            <div>
                                <PinkButton onClick={onClick} className={styles.pinkButton}>
                                    <span className={styles.big}>
                                        <FormattedMessage
                                            id="buttons.start.big"
                                            defaultMessage="Commencer"
                                            desctription="Button label"
                                        />
                                    </span>
                                    <span className={styles.small}>
                                        <FormattedMessage
                                            id="buttons.start.small"
                                            defaultMessage="(bla bla)"
                                            desctription="Button label"
                                        />
                                    </span>
                                </PinkButton>
                            </div>
                        </div>
                    </Transition>
                )}
            </div>
        </div>
    );
};

HomePage.propTypes = propTypes;

export default HomePage;
