import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from '../../styles/icons/default.module.scss';

const propTypes = {
    color: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    color: '#FFF',
    className: null,
};

const WithIcon = ({ color, className }) => {
    return (
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            width="16.5px"
            height="16.5px"
            viewBox="0 0 16.5 16.5"
            className={classNames([styles.container, { [className]: className !== null }])}
            xmlSpace="preserve"
        >
            <polygon
                fill={color}
                points="16.5,2.7 13.8,0 8.2,5.5 2.7,0 0,2.7 5.5,8.2 0,13.8 2.7,16.5 8.2,11 13.8,16.5 16.5,13.8 11,8.2 "
            />
        </svg>
    );
};

WithIcon.propTypes = propTypes;
WithIcon.defaultProps = defaultProps;

export default WithIcon;
