/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/no-danger */
import React, { useCallback, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { useSafariException } from '../contexts/SiteContext';

import * as AppPropTypes from '../../lib/PropTypes';
import Button from '../buttons/Button';
import ExternalIcon from '../icons/External';
import PlayIcon from '../icons/Play';

import { sendEvent } from '../../lib/analytics';

import styles from '../../styles/partials/show.module.scss';

const propTypes = {
    show: AppPropTypes.show,
    className: PropTypes.string,
};

const defaultProps = {
    show: null,
    className: null,
};

const ShowPartial = ({ show, className }) => {
    const oldSafari = useSafariException();
    const videoRef = useRef();
    const [showPlay, setShowPlay] = useState(true);
    const [initialPlay, setInitialPlay] = useState(false);

    const { name = null, image = null, video = null, url = null } = show || {};

    const onClickLink = useCallback(() => {
        sendEvent('click_link', 'interactions', `${url}`);
    }, [url]);

    const onClickPlay = useCallback(() => {
        if (videoRef.current) {
            if (showPlay) {
                sendEvent('click_play', 'interactions', `${show.slug}`);
                videoRef.current.play();
                setShowPlay(false);
                setInitialPlay(true);
            } else {
                sendEvent('click_pause', 'interactions', `${show.slug}`);
                videoRef.current.pause();
                setShowPlay(true);
            }
        }
    }, [show, videoRef, showPlay, setShowPlay, setInitialPlay, sendEvent]);

    const videoImage =
        (video !== null && image !== null && oldSafari && initialPlay) ||
        (video !== null && !oldSafari && image !== null)
            ? image
            : null;

    return (
        <div className={classNames([styles.container, { [className]: className !== null }])}>
            {video !== null ? (
                <div className={styles.image}>
                    <button
                        className={classNames([
                            styles.play,
                            { [styles.hidden]: showPlay === false },
                        ])}
                        type="button"
                        onClick={onClickPlay}
                    >
                        <PlayIcon className={styles.icon} />
                    </button>
                    <video ref={videoRef} playsInline poster={videoImage}>
                        <source src={video} type="video/mp4" />
                    </video>
                </div>
            ) : null}
            {image !== null && video === null ? (
                <div className={styles.image}>
                    <img src={image} alt={name} />
                </div>
            ) : null}
            <div className={styles.bottom}>
                <span className={styles.name}>{name}</span>
                <Button className={styles.link} href={url} onClick={onClickLink} external>
                    <span className={styles.label}>
                        <FormattedMessage
                            id="buttons.show.see_episode"
                            defaultMessage="See episode"
                            description="See episode"
                        />
                    </span>
                    <ExternalIcon className={styles.icon} />
                </Button>
            </div>
        </div>
    );
};

ShowPartial.propTypes = propTypes;
ShowPartial.defaultProps = defaultProps;

export default ShowPartial;
